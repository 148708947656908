import React from 'react'
import { Link } from 'react-router-dom'
import { RankUp } from '../../assests/images/icons'
import {DashboardTable} from "../../assests/mockdata/Array"
const TableData = () => {
  return (
    <div id="projects-table" class="tab-pane   in active active  ">
    <div className="table-responsive">
      <table className="table table-striped table-bordered">
          <thead>
              <tr>
                  <th><input type="checkbox" name="" id=""/></th>
                  <th>Project Name</th>
                  <th>Date Added</th>
                  <th>Total Keywords</th>
                  <th>Top 3</th>
                  <th>Top 10</th>
                  <th>Top 80</th>
                  <th>Top 30</th>
                  <th>Top 100</th>
                  <th>Actions</th>
              </tr>
          </thead>
          <tbody>
          {DashboardTable.map(({project,date,totalKeys,top3,top10,img,top80,top30,top100})=>{
            return(
                <tr>
                  <td><input type="checkbox" name="" id=""/></td>
                  <td>{project}</td>
                  <td>{date}</td>
                  <td>{totalKeys}</td>
                  <td>{top3}</td>
                  <td>{top10}</td>
                  <td>
                      <div className="d-flex justify-content-between">
                          <span><RankUp/> {top80}</span>
                          <span>{top80}</span>
                      </div>
                  </td>
                  <td>{top30}</td>
                  <td>
                      <div className="d-flex justify-content-between">
                          <span><RankUp/>{top100}</span>
                          <span>{top100}</span>
                      </div>
                  </td>
                  <td>
                      <Link to="#" title="Edit" className="text-primary mr-2"><i className="fa fa-edit"></i></Link>
                      <Link to="#" title="Delete" className="text-danger"><i className="fa fa-trash"></i></Link>
                  </td>
              </tr>

            )
          }) }
              
          </tbody>
      </table>
    </div>
  </div>
  )
}

export default TableData