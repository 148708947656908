import React from 'react'
import { AddIcn } from '../../images'
import { LeftArrow } from '../../images/icons'
import { Link } from "react-router-dom";

const GoogleOganic = () => {
  return (
    <div id="google-organic" className="tab-pane in active">
                    <div className="form-group mt-4">
                        <label><i className="fas fa-link"></i> Enter Url</label>
                        <div className="url-field">
                            <div className="prefix">http(s)://</div>
                            <input type="text" placeholder="Domain.com"/>
                            <div className="custom-dropdown">
                                <Link className="custom-dropdown-toggle" role="button">*.domain.com/*</Link>
                                    <div className="custom-dropdown-menu shadow fade">
                                        <div className="close-custom-dropdown">
                                            ×
                                        </div>
                                       <h5>*.domain.com/* <span><i className="far fa-question-circle"></i></span></h5>
                                       <p>All subdomains and all pages</p>
                                       <div className="dropdown-divider"></div>
                                       <h5>URL<span><i className="far fa-question-circle"></i></span></h5>
                                       <p>Exact URL</p>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <label><i className="fas fa-tag"></i> Tags optional</label>
                        <input type="text" className="form-control" placeholder="Add Tag"/>
                    </div>
                    <div className="form-group">
                         <div className="ios-switch">
                            Keywords
                            <div className="cl-toggle-switch">
                                <label className="cl-switch">
                                    <input type="checkbox"/>
                                    <span></span>
                                </label>
                            </div>
                            <span>Location</span>
                        </div>
                    </div>
                    <div className="keyword-track-div">
                        <div className="keywrods-to-track">
                            <div className="form-group">
                                <label><i className="fas fa-label"></i> Keywords to Track</label>
                                <textarea name="" id="" cols="30" rows="10" className="form-control" placeholder="Input one keyword per lline"></textarea>
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <div className="form-group">
                                        <label><i className="fas fa-vector-square"></i> Regions</label>
                                        <select name="" id="" className="form-control">
                                            <option>Nov 1st. 2022</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="form-group">
                                        <label><i className="fas fa-globe"></i> Language</label>
                                        <select name="" id="" className="form-control">
                                            <option>100</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Location Optional</label>
                                <input type="text" className="form-control" placeholder="Zip/Postal code or city"/>
                            </div>
                            <div className="form-group">
                                <Link to="#"><i className="fas fa-plus-circle"></i> Add Location</Link>
                            </div>
                            <div className="form-group ignore-listing">
                                <div className="ios-switch">
                                   <div className="cl-toggle-switch">
                                       <label className="cl-switch">
                                           <input type="checkbox"/>
                                           <span></span>
                                       </label>
                                   </div>
                                   <span>Ignore local listings!</span>
                               </div>
                               <div className="dropdown">
                                <Link to="#" className="dropdown-toggle" role="button" data-toggle="dropdown" aria-expanded="true"><i className="fas fa-desktop"></i> Desktop</Link>
                                <div className="dropdown-menu dropdown-menu-right shadow " aria-labelledby="homeActionDropdown1">
                                    <Link className="dropdown-item" to="#">
                                        Desktop
                                    </Link>
                                    <div className="dropdown-divider"></div>
                                    <Link className="dropdown-item" to="#">
                                       Mobile
                                    </Link>
                                    <div className="dropdown-divider"></div>
                                    <Link className="dropdown-item" to="#">
                                        Desktop & Mobile
                                    </Link>
                                    
                                    
                                </div>
                               </div>
                           </div>
                           <div className="form-group">
                            <label>GMB Tracking by Name optional</label>
                            <input type="text" className="form-control" placeholder="e.g. Dentistry On West Queen Street"/>
                           </div>
                           <div className="form-group">
                            <Link to="/addkeywords" className="btn btn-success add-project-btn">
                            {/* <img src="img/add-icn.svg" className="mr-1"/> */}
                            <AddIcn className="mr-1" />
                            Add Keyword</Link>
                           </div>
                        </div>
                        <div className="suggested-keywords">
                            <div className="form-group">
                                <label>Suggested Keywords</label>
                                <div className="keywords-adder">
                                    <div className="card shadow-sm">
                                        <div className="card-body">
                                            <div className="card-title">
                                                <h3><LeftArrow/> Start Typing Keywords <span>for additional suggestions </span></h3>
                                                <textarea name="" id="" cols="30" rows="10" className="form-control border-0"></textarea>
                                                <div className="keywords-counter text-right mt-4">
                                                    1-8 of 8
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
  )
}

export default GoogleOganic