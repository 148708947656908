import React from "react";
import { Link } from "react-router-dom";

const EditDropdown = () => {
  return (
    <div
      className="dropdown-menu dropdown-menu-right shadow show dropdown-menu-lg"
      aria-labelledby="editDropDown"
      style={{translate:"15%"}}
    >
      <div className="form-group">
        <label>Update URL to http(s:)//</label>
        <input
          type="text"
          className="form-control"
          placeholder="riversidetowing.us"
        />
      </div>
      <div className="form-group">
        <label>Region</label>
        <div className="region-select">
          <select name="show" id="" className="form-control">
            <option value="Everything">Google.com</option>
            <option>Google Organic (Everything)</option>
            <option>Google Organic (Desktop)</option>
            <option>Google Organic (Mobile)</option>
            <option>Google Maps</option>
            <option>Youtube</option>
          </select>
          <div className="note">
            Modifying the 'Mail From' email can result the report ending up in
            the JUNK folder
          </div>
        </div>
      </div>
      <div className="form-group">
        <label>Language</label>
        <select name="show" id="" className="form-control">
          <option value="Everything">en(English)</option>
          <option>fr(French)</option>
        </select>
      </div>
      <div className="form-group">
        <label>Local Keyword Settings</label>
        <div className="ios-switch">
          <div className="cl-toggle-switch">
            <label className="cl-switch">
              <input type="checkbox" />
              <span></span>
            </label>
          </div>
          <span>Location</span>
        </div>
      </div>
      <div className="form-group">
        <label>Search Location optional</label>
        <input
          type="text"
          className="form-control"
          placeholder="Search location"
        />
        <div className="ios-switch mt-3">
          <div className="cl-toggle-switch">
            <label className="cl-switch">
              <input type="checkbox" />
              <span></span>
            </label>
          </div>
          <span>Ignore local listings</span>
        </div>
      </div>
      <div className="form-group">
        <label>GMB Name Tracking optional</label>
        <input
          type="text"
          className="form-control"
          placeholder="GMB Name Tracking"
        />
      </div>
      <Link to="#" className="btn btn-danger">
        Cancel
      </Link>
      <Link to="#" className="btn btn-success">
        Apply
      </Link>
    </div>
  );
};

export default EditDropdown;
