import React from 'react'
import { HidekeyColumn, projectColumn } from '../../assests/mockdata/Array'

const Table = () => {
  return (
    <div id="table" className="tab-pane in active">
    <div className="project-table-settings-cont">
     <h3 className="mt-4">Project Table Settings</h3>
     <div className="row">
         <div className="col-12 col-lg-6">
             <div className="alert alert-success mt-3">
                 Customize your display settings here, play around with settings until they fit your style.
             </div>
             <div className="card mt-4 form-card shadow-sm">
                 <div className="card-body">
                     <div className="table-width mb-4">
                         <div className="btn-group btn-block" role="group" aria-label="Basic example">
                             <button type="button" className="btn btn-secondary btn-block">Standard</button>
                             <button type="button" className="btn btn-success btn-block mt-0">Extra Wide</button>
                         </div>
                     </div>
                     
                     <div className="row">
                         <div className="col-12 col-lg-6">
                             <div className="form-group">
                                 <label>List Projects</label>
                                 <input type="text" className="form-control"/>
                             </div>
                         </div>
                         <div className="col-12 col-lg-6">
                             <div className="form-group">
                                 <label>Project Metrics</label>
                                 <input type="text" className="form-control"/>
                             </div>
                         </div>
                     </div>
                     <div className="row">
                         <div className="col-lg-12">
                             <label>Metric Ranges</label>
                         </div>
                         <div className="col">
                             <div className="form-group">
                                 <input type="text" className="form-control"/>
                             </div>
                         </div>
                         <div className="col">
                             <div className="form-group">
                                 <input type="text" className="form-control"/>
                             </div>
                         </div>
                         <div className="col">
                             <div className="form-group">
                                 <input type="text" className="form-control"/>
                             </div>
                         </div>
                         <div className="col">
                             <div className="form-group">
                                 <input type="text" className="form-control"/>
                             </div>
                         </div>
                         <div className="col">
                             <div className="form-group">
                                 <input type="text" className="form-control"/>
                             </div>
                         </div>
                     </div>
                     <div className="row">
                         <div className="col-12">
                             <label>Short Table By</label>
                         </div>
                         <div className="col-12 col-lg-6">
                             <div className="form-group">
                                 <select name="" id="" className="form-control">
                                     <option>Monthly Search Value</option>
                                 </select>
                             </div>
                         </div>
                         <div className="col-12 col-lg-6">
                             <div className="form-group">
                                 <select name="" id="" className="form-control">
                                     <option>Descending</option>
                                 </select>
                             </div>
                         </div>
                     </div>
                     <div className="row">
                         <div className="col-12 col-lg-6">
                             <div className="form-group">
                                 <label>Date Format</label>
                                 <select name="" id="" className="form-control">
                                     <option>Nov 1st, 2022</option>
                                 </select>
                             </div>
                         </div>
                         <div className="col-12 col-lg-6">
                             <div className="form-group">
                                 <label>Keywords to Display per page</label>
                                 <select name="" id="" className="form-control">
                                     <option>100</option>
                                 </select>
                             </div>
                         </div>
                     </div>
                     <div className="form-group">
                         <label>Chart Height</label>
                         <div className="slidecontainer">
                             <input type="range" min="1" max="100" value="50" className="slider" id="myRange"/>
                             <p>Value: <span id="demo"></span></p>
                         </div>
                     </div>
                 </div>
             </div>
         </div>
         <div className="col-12 col-lg-6">
             <div className="card mt-3 form-card shadow-sm">
                 <div className="card-body">
                     <div className="row">
                         <div className="col-12 col-lg-6 proj-tab-columns-checkboxes">
                             <h3>Project Table Columns</h3>
                             {projectColumn.map(({label,type,name})=>{
                                return(
                                    <div className="form-group">
                                 <label>{label}<input type={type} name={name} id=""/></label>
                             </div>

                                )
                             })}
                             
                            
                         </div>
                         <div className="col-12 col-lg-6 proj-tab-columns-checkboxes">
                             <h3>Hide in ViewKey</h3>
                             {HidekeyColumn.map(({label,type,name})=>{
                                return(
                                    <div className="form-group">
                                 <label>{label} <input type={type} name={name} id=""/></label>
                             </div>

                                )
                             })}
                             
                            
                         </div>
                     </div>
                 </div>
             </div>
             <h3 className="mt-4">Other Settings</h3>
             <div className="card mt-3 form-card shadow-sm">
                 <div className="card-body">
                     <div className="row">
                         <div className="col-12 col-lg-6 proj-tab-columns-checkboxes">
                             <div className="form-group">
                                 <label>Display Groups in Parent Projects <input type="checkbox" name="" id=""/></label>
                             </div>
                             <div className="form-group">
                                 <label>Fill in Rank Drop Gaps in Charts <input type="checkbox" name="" id=""/></label>
                             </div>
                         </div>
                         <div className="col-12 col-lg-6 proj-tab-columns-checkboxes">
                             <div className="form-group">
                                 <label>Display Keywords totals <input type="checkbox" name="" id=""/></label>
                             </div>
                         </div>
                     </div>
                     <div className="form-group mb-2">
                         <label><strong>Display Ranking Area Chart in Project Dashboard </strong> </label><br />
                         <label>Show <input type="radio" name="vk2" id=""/></label>
                         <label>Hide <input type="radio" name="vk2" id=""/></label>
                     </div>
                     <div className="form-group mb-2">
                         <label><strong>Google Analytics Charts </strong> </label><br />
                         <label>Show <input type="radio" name="vk3" id=""/></label>
                         <label>Hide <input type="radio" name="vk3" id=""/></label>
                     </div>
                     <div className="form-group mb-2">
                         <label><strong>Monthly Search Volume for Google.com Keywords</strong> </label><br />
                         <label>Show <input type="radio" name="vk4" id=""/></label>
                         <label>Hide <input type="radio" name="vk4" id=""/></label>
                     </div>
                 </div>
             </div>
         </div>
     </div>
    </div>
 </div>
  )
}

export default Table