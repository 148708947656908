import React from 'react'
import { projectTable } from '../../assests/mockdata/Array'

const Table = () => {
  return (
    <div id="projects-table" className="tab-pane in active">
    <div className="table-responsive">
      <table className="table table-striped table-bordered">
          <thead>
              <tr>
                  <th>Rank</th>
                  <th>Share</th>
                  <th>% Change</th>
                  <th>Domain</th>
              </tr>
          </thead>
          <tbody>
          {projectTable.map(({rank,share,percent,domain})=>{
            return(
                <tr>
                  <td>{rank}</td>
                  <td>{share}</td>
                  <td>{percent}</td>
                  <td>{domain}</td>
              </tr>

            )
          })}
              
              
          </tbody>                       
      </table>
    </div>
  </div>
  )
}

export default Table