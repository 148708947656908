import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { BellIcn, EnvelopeIcn, EpRefresh, NavToggler, Profile1, Profile2, Profile3, UndrawProfile ,Dropdwn} from '../../images'
import LogOut from './LogOut'
import MessageAlert from './MessageAlert'
import MessageCenter from './MessageCenter'
// import {ep-refresh-icn} from "../../images/ep-refresh-icn.svg"

const Topbar = ({name,subname}) => {
    const [showAlert,setShowAlert]=useState(false)
    const [showMsg,setShowMsg]=useState(false)
    const [dropdown,setDropdown]=useState(false)
    const [logout,setLogout]=useState(false)
    const [sideOpen,setSideOpen]=useState(false)
    const ref = useRef(null)
    const showMesageCenter=()=>{
        setShowMsg(!showMsg)
        setShowAlert(false)
    }
    const showMesageAlert=()=>{
        setShowMsg(false)
        setShowAlert(!showAlert)
    }
    React.useEffect(() => {
        const handleClickOutside = (event) => {
          if (!ref?.current?.contains(event.target)) {
            setShowMsg(false);
            setShowAlert(false)
          }
        };
        document.addEventListener("mousedown", handleClickOutside);
      }, [ref]);
  return (
    <div ref={ref} className="container-fluid mt-3">
   
        <div className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

{/* <!-- Sidebar Toggle (Topbar) --> */}
<div className={`nav-toggler ${sideOpen?"side-opened":""} mr-3`}>
    <button id="" className="btn p-0" onClick={()=>setSideOpen(!sideOpen)}>
       {/* / <img src={refresh} alt=""/> */}
        <NavToggler/>
    </button>
   </div>
<div className="page-title-cont">
    <h4>{name} <span className="refresh-icn"><EpRefresh />
    {/* <img src={} alt="" /> */}
    </span></h4>
    <span className="last-updated">{subname}</span>
</div>



{/* <!-- Topbar Navbar --> */}
<ul className="navbar-nav ml-auto">
    <li className="nav-item">
        {/* <!-- Topbar Search --> */}
        <div className="search-box">
            <input type="text" placeholder="search"/>
            <button className="btn search-btn p-0"><i className="fas fa-search fa-sm"></i></button>
           </div>
       
    </li>
    
    {/* <!-- Nav Item - Search Dropdown (Visible Only XS) --> */}
    <li className="nav-item dropdown no-arrow d-sm-none">
        <a className="nav-link dropdown-toggle" to="#" id="searchDropdown" role="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fas fa-search fa-fw"></i>
        </a>
        {/* <!-- Dropdown - Messages --> */}
        <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
            aria-labelledby="searchDropdown">
            <form className="form-inline mr-auto w-100 navbar-search">
                <div className="input-group">
                    <input type="text" className="form-control bg-light border-0 small"
                        placeholder="Search for..." aria-label="Search"
                        aria-describedby="basic-addon2"/>
                    <div className="input-group-append">
                        <button className="btn btn-primary" type="button">
                            <i className="fas fa-search fa-sm"></i>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </li>

    {/* <!-- Nav Item - Alerts --> */}
   
    {/* <!-- Nav Item - Messages --> */}
    <li className="nav-item dropdown no-arrow mx-1 msg-drop">
        <Link className="nav-link dropdown-toggle" to="#" id="messagesDropdown" role="button"
            data-toggle="dropdown" aria-haspopup="true"  aria-expanded={showMsg? "true":"false"} onClick={showMesageCenter}>
            {/* <!--<i className="fas fa-envelope fa-fw"></i>--> */}
            {/* <img src={EnvelopeIcn} alt="" /> */}
            <EnvelopeIcn />
            {/* <!-- Counter - Messages --> */}
            <span className="badge badge-success badge-counter">7</span>
        </Link>
        {/* <!-- Dropdown - Messages --> */}
        {showMsg && 
        <MessageCenter setShowMsg={setShowMsg} showMsg={showMsg}/>}
    </li>
    <li className="nav-item dropdown no-arrow mx-1 notification-drop">
        <Link className="nav-link dropdown-toggle" to="#" id="alertsDropdown" role="button"
            data-toggle="dropdown" aria-haspopup="true"  aria-expanded={showAlert? "true":"false"} onClick={showMesageAlert}>
            {/* <!--<i className="fas fa-bell fa-fw"></i>--> */}
            {/* <img src={BellIcn} alt=""/> */}
            <BellIcn />
            {/* <!-- Counter - Alerts --> */}
            <span className="badge badge-success badge-counter">3+</span>
        </Link>
        {/* <!-- Dropdown - Alerts --> */}
        {showAlert && 
       <MessageAlert  setShowAlert={setShowAlert} showAlert={showAlert}/>}
    </li>

    

    {/* <!-- Nav Item - User Information --> */}
    <li className="nav-item dropdown no-arrow">
        <Link className="nav-link dropdown-toggle" to="#" id="userDropdown" onClick={()=>setDropdown(!dropdown)}
         role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded={dropdown? "true":"false"}>
            {/* <img className="img-profile rounded-circle mr-2"
                src={UndrawProfile}/> */}
                <UndrawProfile className="img-profile rounded-circle mr-2"/>
            <span className=" d-none d-lg-inline text-gray-600 small mr-5">
                <span className="userName d-block">Johny Adson</span>
                <span className="email-addrs">vad@logicalcommerce.com</span>
            </span>
            {/* <img src={Dropdwn} alt="" /> */}
            <Dropdwn />
            
        </Link>
        {/* <!-- Dropdown - User Information --> */}
        {dropdown && 

        <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in show"
            aria-labelledby="userDropdown">
            <Link className="dropdown-item" to="#">
                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                Profile
            </Link>
            <Link className="dropdown-item" to="/setting">
                <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                Settings
            </Link>
            <Link className="dropdown-item" to="#">
                <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                Activity Log
            </Link>
            <div className="dropdown-divider"></div>
            <Link className="dropdown-item" to="#" data-toggle="modal" data-target="#logoutModal" 
            onClick={()=>setLogout(!logout)}>
                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                Logout
            </Link>

        </div>
        }
    </li>

</ul>
 {
    logout && 
    <LogOut setLogout={setLogout}/>
}
</div>

    </div>
  )
}

export default Topbar