import React from 'react'
import { Link } from 'react-router-dom'
import { BillIcn, DismisBill } from '../../assests/images/icons'

const BillingModal = ({setModal}) => {
  return (
    <div className="modal fade show" id="billingModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true" style={{display:'block'}}>
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="close-modal">
                    <button className="close" type="button" data-dismiss="modal" aria-label="Close" onClick={()=>setModal(false)}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <h3>Your billing details need updating.</h3>
                    <p>We have noticed that your profile is missing certain mandatory billing details like country or zip code.</p>
                    <p>To ensure the correct taxes (if applicable) are applied, please update your billing details.</p>
                    <Link to="#" className="btn btn-success mr-1"><BillIcn className="mr-1"/> Update Billing Details</Link>
                    <Link to="#" className="btn btn-primary " data-dismiss="modal" onClick={()=>setModal(false)}><DismisBill  className="mr-1"/>
                    Dismiss</Link>
                </div>
                
            </div>
        </div>
    </div>
  )
}

export default BillingModal