import React from 'react'
import { CompanyIcn, LinkIcn, LogoIcn, NoteIcn, SaveIcn } from '../../assests/images/icons'
import company_logo from "../../assests/images/company_logo.jpg"
import vk_img1 from "../../assests/images/vk_img1.jpg"
import vk_img2 from "../../assests/images/vk_img2.jpg"
import { Link } from 'react-router-dom'

const ViewkeySetting = ({setShowViewkey}) => {
  return (
    <div className="modal fade show" id="viewkeySettingsModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-modal="true" style={{display:'block'}}>
    <div className="modal-dialog modal-xxl" role="document">
        <div className="modal-content">
            <div className="close-modal">
                <button className="close" type="button" data-dismiss="modal" aria-label="Close" onClick={()=>setShowViewkey(false)}>
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="row">
                    <div className="col-12 col-lg-4">
                        <div className="viewkey-settings-cont">
                            <h2>ViewKey Project Settings <span>Project Specific</span></h2>
                            <div className="form-group">
                                <label><strong>Require a Password for the shared ViewKey</strong> </label><br />
                                <label>Yes <input type="radio" name="vk1" id=""/></label>
                                <label>No <input type="radio" name="vk1" id=""/></label>
                            </div>
                            <div className="form-group">
                                <label>Set Password for the ViewKey </label>
                                <input type="text" className="form-control" placeholder="3-100 Characters"/>
                            </div>
                            <div className="form-group">
                                <label><strong> Show Share of Voice Overview in shared ViewKey </strong> </label><br />
                                <label>Yes <input type="radio" name="vk2" id=""/></label>
                                <label>No <input type="radio" name="vk2" id=""/></label>
                            </div>
                            <div className="form-group">
                                <label><strong> Override Account-Wide ViewKey Branding  </strong> </label><br/>
                                <label>Yes <input type="radio" name="vk3" id=""/></label>
                                <label>No <input type="radio" name="vk3" id=""/></label>
                            </div>
                            <div className="card mb-4">
                                <div className="card-body">
                                    <div className="form-group">
                                        <label><CompanyIcn/> Company Name</label>
                                        <input type="text" className="form-control" placeholder="Your Company Name"/>
                                    </div>
                                    <div className="form-group">
                                        <label><LinkIcn/> Company URL Link</label>
                                        <input type="text" className="form-control" placeholder="support@keyword.com"/>
                                    </div>
                                    <div className="form-group">
                                        <label><LogoIcn/> Company Logo URL</label>
                                        <input type="text" className="form-control" placeholder="https://app.keyword.com/images/logo.png"/>
                                    </div>
                                    <div className="form-group">
                                        <label><NoteIcn/> Company Description</label>
                                        <textarea name="" id="" cols="30" rows="5" className="form-control" placeholder="Keyword.com Rank Tracking Service - Whitelabel Reports"></textarea>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="form-group">
                                <Link to="#" className="btn btn-success add-project-btn">
                               <SaveIcn className="mr-1"/> Save Changes</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-8">
                        <div className="viewkey-keyword-report-cont">
                            <div className="title-cont">
                                <h2>Ranking Report Data Verified By Keyword.com</h2>
                                <Link to="#" className="btn btn-outline-primary">Leanr More</Link>
                            </div>
                            <div className="report-banner mb-4">
                                <div className="company-logo">
                                    <img src={company_logo} alt="" />
                                </div>
                                <div className="company-info">
                                    <h3>Company Name </h3>
                                    <p>
                                        Company description - say hi to your customers and prospects!
                                    </p>
                                </div>
                            </div>
                            <h4>Group Name </h4>
                            <p>Last Refreshed:11 hours ago (Nov 20th, 2018)</p>
                            <div className="vk-img">
                                <img src={vk_img1} alt="" />
                            </div>
                            <div className="vk-img mt-4">
                                <img src={vk_img2} alt="" />
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</div>
  )
}

export default ViewkeySetting