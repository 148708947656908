import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { SaveIcn } from '../../assests/images/icons'
import Newproject from './Newproject'

const Account = () => {
    const[showSidebar,setShowSidebar]=useState(false)
  return (
    <div id="account" className="tab-pane in active">
    <div className="row">
        <div className="col-12 col-lg-6">
            <h3 className="mt-4">Account Details</h3>
            <div className="card mt-4 form-card shadow-sm">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label><i className="fas fa-user-circle"></i> Full Name</label>
                                <input type="text" className="form-control" placeholder="Enter Full Name"/>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label><i className="far fa-address-card"></i> Contact Number</label>
                                <input type="text" className="form-control" placeholder="Enter Full Name"/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label><i className="fas fa-globe-americas"></i> Country</label>
                                <select name="" id="" className="form-control">
                                    <option>United States</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label><i className="fas fa-share-alt"></i> Country State</label>
                                <select name="" id="" className="form-control">
                                    <option>Alabama</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-0">
                        <Link to="#" className="btn btn-success add-project-btn" onClick={()=>setShowSidebar(!showSidebar)}><SaveIcn className="mr-1" />Save Changes</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-12 col-lg-6">
            <h3 className="mt-4">Change Password</h3>
            <div className="card mt-4 form-card shadow-sm">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label><i className="fas fa-lock"></i> Current Password</label>
                                <input type="password" className="form-control" placeholder="Enter Full Name"/>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label><i className="fas fa-lock"></i> New Password</label>
                                <input type="password" className="form-control" placeholder="Enter Full Name"/>
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-0">
                        <Link to="#" className="btn btn-success add-project-btn"onClick={()=>setShowSidebar(!showSidebar)}><SaveIcn className="mr-1" />Save Changes</Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {showSidebar && <Newproject setShowSidebar={setShowSidebar}/>}
</div>
  )
}

export default Account