import React from 'react'
import { Link } from 'react-router-dom'
import { ExpIcn1, ExpIcn2 } from '../../assests/images/icons'

const ExportModal = ({setExportModal}) => {
  return (
    <div className="modal fade show" id="exportModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-modal="true" style={{display:'block'}}>
    <div className="modal-dialog" role="document">
        <div className="modal-content">
            <div className="close-modal">
                <button className="close" type="button" data-dismiss="modal" aria-label="Close" onClick={()=>setExportModal(false)}>
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div className="modal-body">
                <h3>Export to CSV</h3>
                <div className="card-primary mt-5 mb-4">
                    <div className="card-body">
                        <div className="card-icn">
                            <Link to="#"><ExpIcn1/></Link>
                        </div>
                        <h4 className="mt-4">Export Project List with Metrics</h4>
                        <p>To ensure the correct taxes (if applicable) are applied, please update your billing details.</p>
                    </div>
                </div>
                <div className="card-success mt-5">
                    <div className="card-body">
                        <div className="card-icn">
                            <Link to="#"><ExpIcn2/></Link>
                        </div>
                        <h4 className="mt-4">Export All Project Keywords</h4>
                        <p>This will export all the Keyword data in your account
                            (Limited to 70,000  keywords)</p>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</div>
  )
}

export default ExportModal