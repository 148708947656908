import React, { useState } from "react";
import { Link } from "react-router-dom";
import GoogleLocalMaps from "../../assests/components/comp/GoogleLocalMaps";
import GoogleOganic from "../../assests/components/comp/GoogleOganic";
import Layout from "../../assests/components/Layout/Layout";
import Topbar from "../../assests/components/Layout/Topbar";
// import "../../assests/Style/style.css";
const AddKeywords = () => {
  const [btn, setBtn] = useState("organic");
  const googleOrganic = () => {
    setBtn("organic");
  };
  const googleMaps = () => {
    setBtn("local");
    console.log("btnn", btn);
  };
  return (
    <Layout>
      <div>
        
          {/* <!-- Content Wrapper --> */}
          {/* <div id="content"> */}
            {/* <!-- Main Content --> */}
            <div id="content">
              <Topbar name={"Add keywords"} subname={"https://orlandotowing.us/(92 Keywords)"}/>

              {/* <!-- Begin Page Content --> */}
              <div className="container-fluid">
                {/* <!-- Content Row --> */}
                <div className="dashboard-tabs project-tabs add-keywords-tabs">
                  <ul className="nav nav-tabs">
                    <li className="active mr-2">
                      <Link
                        className={btn === "organic" ? "active" : ""}
                        data-toggle="tab"
                        onClick={googleOrganic}
                      >
                        Google Organic
                      </Link>
                    </li>
                    <li>
                      <Link
                        data-toggle="tab"
                        className={`projects-table-btn" ${
                          btn === "local" ? "active" : ""
                        }`}
                        onClick={googleMaps}
                      >
                        Google Local Maps
                      </Link>
                    </li>
                  </ul>

                  <div className="tab-content">
                    {btn === "organic" ? (
                      <GoogleOganic />
                    ) : btn === "local" ? (
                      <GoogleLocalMaps />
                    ) : null}
                  </div>
                </div>
              </div>
              {/* <!-- /.container-fluid --> */}
            </div>
            {/* <!-- End of Main Content --> */}
          {/* </div> */}
          {/* <!-- End of Content Wrapper --> */}
       
      </div>
    </Layout>
  );
};

export default AddKeywords;
