import React from 'react'
import { Link } from 'react-router-dom'

const EditProject = ({setShowEdit}) => {
  return (
    <div className="edit-project side-popup open">
        <div className="title">
            <h3>Edit Project</h3>
            <p>https://columbustowing.us/ (85)</p>
            <div className="close-side-popup" onClick={()=>setShowEdit(false)}>&times;</div>
        </div>
        <div className="field-group">
            <label><img src="img/icons/project-name.svg" alt="" /> Project Name</label>
            <input type="text" className="form-control shadow-sm" placeholder="Enter a name for your Project" value="https://columbustowing.us/"/>
        </div>
        <div className="side-btm btns-cont">
            <Link to="#" className="btn btn-success btn-block add-project-btn"><i className="fas fa-sync-alt"></i> Update Project</Link>
            <Link to="#" className="btn btn-danger btn-block cancel-btn" onClick={()=>setShowEdit(false)}>Cancel</Link>
        </div>
    </div>
  )
}

export default EditProject