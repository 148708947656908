import React from 'react'
import { Link } from 'react-router-dom'
import { UpdateCrCard, UpdatePlanIcn } from '../../assests/images/icons'
import { billingTable } from '../../assests/mockdata/Array'

const Billing = () => {
  return (
    <div id="billing" className="tab-pane in active">
    <div className="billing-info">
     <div className="row">
         <div className="col-12 col-lg-4">
             <h3>Current Plan</h3>
             <div className="card curent-plan-card shadow-sm">
                 <div className="card-body">
                     <h4>Current Plan</h4>
                     <p>2,500 Keywords with daily updates </p>
                     <h5>$279.00/monthly </h5>
                     <Link to="#" className="btn btn-default mt-3 mb-3"><UpdatePlanIcn/>Update Plan</Link>
                     <div className="renew-notice">
                         Your subscription will renew on <span className="ren-date">Nov 12th, 2022</span> 
                     </div>
                 </div>
             </div>
         </div>
         <div className="col-12 col-lg-4">
             <h3>Payment Details </h3>
             <div className="card shadow-sm">
                 <div className="card-body">
                     <div className="form-group">
                         <label>Card Number </label>
                         <div className="input-icn">
                             <input type="text" className="form-control" placeholder="**** **** **** 4516"/>
                             <div className="icn-cont"><i className="far fa-credit-card"></i></div>
                         </div>
                     </div>
                     <div className="row">
                         <div className="col-12 col-lg-6">
                             <div className="form-group">
                                 <label>Expiry Date </label>
                                 <input type="text" className="form-control" placeholder="**/26"/>
                             </div>
                         </div>
                         <div className="col-12 col-lg-6">
                             <div className="form-group">
                                 <label>CVC</label>
                                 <input type="password" className="form-control" placeholder="***"/>
                             </div>
                         </div>
                     </div>
                     <div className="form-group mb-0">
                         <Link to="#" className="btn btn-success"><UpdateCrCard className="mr-1"/>Update Credit Card</Link>
                     </div>
                 </div>
             </div>
         </div>
         <div className="col-12 col-lg-4">
             <h3>Invoices </h3>
             <div className="card shadow-sm bg-gray">
                 <div className="card-body">
                     <Link to="#" className="btn btn-primary mb-4"><UpdateCrCard className="mr-1"/>Update Billing Details</Link>

                     <div className="table-responsive">
                         <table className="table table-bordered table-striped">
                             <thead>
                                 <tr>
                                     <th>Date</th>
                                     <th>Amount</th>
                                     <th>Download</th>
                                 </tr>
                             </thead>
                             <tbody>
                             {billingTable.map(({date,amount,download})=>{
                                return(
                                    <tr>
                                     <td>{date}</td>
                                     <td>{amount}</td>
                                     <td><Link to="#">{download}</Link></td>
                                 </tr>

                                )
                             })}
                                 
                                
                             </tbody>
                         </table>
                     </div>
                 </div>
             </div>
             <div className="text-right cancel-subs mt-4">
                 <Link to="#" className="text-danger">Cancel your subscription </Link>
             </div>
         </div>
     </div>
    </div>
 </div>
  )
}

export default Billing