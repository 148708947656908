import React from 'react'
import {Link} from "react-router-dom"

const ArchiveProject = ({setArchive}) => {
  return (
    <div className="modal fade show" id="archiveModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-modal="true" style={{display:'block'}}>
    <div className="modal-dialog" role="document">
        <div className="modal-content">
            <div className="close-modal">
                <button className="close" type="button" data-dismiss="modal" aria-label="Close" onClick={()=>setArchive(false)}>
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div className="modal-body">
                <h3>Archive </h3>
                <div className="text-center mt-3">
                    <Link to="#" className="btn btn-danger archive-btn">Archive</Link>
                </div>
                
                
            </div>
            
        </div>
    </div>
</div>
  )
}

export default ArchiveProject