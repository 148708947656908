import React, { useState } from "react";
import { Link } from "react-router-dom";
import AddProjectPopup from "../../../Pages/AddKeywords/AddProjectPopup";
import { AddIcn } from "../../images";
import { sideBar } from "../../mockdata/sidebar";
// import "../../Style/style.css"
const Sidebar = () => {
  const [open, setOpen] = useState(false);
  const openPopup = () => {
    setOpen(!open);
  };
  return (
    <div>
      <ul
        className="navbar-nav bg-white sidebar sidebar-light accordion"
        id="accordionSidebar"
      >
        {/* <!-- Sidebar - Brand --> */}
        <Link
          className="sidebar-brand d-flex align-items-center justify-content-between"
          to="/"
        >
          <div className="sidebar-brand-text mx-0">Keyword.com</div>
        </Link>

        {/* <!-- <button id="sidebarToggleTop" className="btn p-0">
    <img src="img/nav-toggler.svg" alt="" />
</button> --> */}
        <div className="search-box">
          <input type="text" placeholder="search" />
          <button className="btn search-btn p-0">
            <i className="fas fa-search fa-sm"></i>
          </button>
        </div>

        {/* <!-- Nav Item - Dashboard --> */}
        <li className="nav-item active mt-1">
          <Link className="nav-link" to="/dashboard" style={{paddingRight:3, paddingTop:8,paddingBottom:8}}>
            <i className="fas fa-fw fa-tachometer-alt"></i>
            <span className="ml-2" >Dashboard</span>
          </Link>
        </li>

        {/* <!-- Heading --> */}
        <div className="sidebar-heading">Project</div>

        {/* <!-- Nav Item - projects Nav --> */}
        <ul className="prjects-nav">
          {sideBar.map(({ projectnName, count }) => {
            return (
              <li className="nav-item project-links">
                <Link className="nav-link" to="/project" style={{paddingRight:3, paddingTop:8,paddingBottom:8}}>
                  <span className="project-name">{projectnName} </span>
                  <div className="count-add-feat">
                    <span className="key-count">{count}</span>
                    <span
                      className="add-feat"
                      data-toggle="modal"
                      data-target="#addTagModal"
                    >
                      {" "}
                      <i className="fas fa-fw fa-plus"></i>
                    </span>
                  </div>
                </Link>
              </li>
            );
          })}
        </ul>

        {/* <!-- Sidebar Message --> */}
        <li className="sidebar-btm-info-box">
          <div className="progress-info">
            <div className="progress-item px-2 mb-2">
              <div className="progress progress-sm">
                <div
                  className="progress-bar progress-red"
                  role="progressbar"
                  style={{
                    width: "85%",
                    ariaValuenow: "85",
                    ariaValuemin: "0",
                    ariaValuemax: "100",
                  }}
                ></div>
              </div>
              <small>2430/2500 Daily refreshes used</small>
            </div>
            <div className="progress-item px-2 mb-2">
              <div className="progress progress-sm ">
                <div
                  className="progress-bar progress-red"
                  role="progressbar"
                  style={{
                    width: "85%",
                    ariaValuenow: "85",
                    ariaValuemin: "0",
                    ariaValuemax: "100",
                  }}
                ></div>
              </div>
              <small>2430/2500 Keywords used</small>
            </div>
            <div className="upgrd-plan-div text-right px-2">
              <Link to="#" className="text-primary">
                Upgrade Plan
              </Link>
            </div>
          </div>
          <div className="add-project px-2 mt-4">
            <Link
              to="#"
              className="btn btn-success btn-block add-project-btn"
              onClick={openPopup}
            >
              {/* <img src={AddIcn} className="mr-1" /> */}
              <AddIcn className="mr-1" />
              Add Project
            </Link>
          </div>
        </li>
        {/* {open &&  */}
        <AddProjectPopup setOpen={setOpen} open={open} />
        {/* } */}
      </ul>
    </div>
  );
};

export default Sidebar;
