import React , { useState }from 'react'
import { Link } from 'react-router-dom'
import { DownWhiteIcn } from '../../assests/images/icons'

const ExportCsv = ({setShowCsv}) => {
    const[show,setShow]=useState("livedata")
  return (
    <div className="modal fade show" id="csvModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-modal="true" style={{display:'block'}}>
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="close-modal">
                    <button className="close" type="button" data-dismiss="modal" aria-label="Close" onClick={()=>setShowCsv(false)}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <h3>Export CSV</h3>
                    <div className="export-csv-options">
                        <label className="mr-2">
                        <input type="radio" name="csv-options" id="" value="Export Live Data" checked onClick={()=>setShow("livedata")}/> Export live data</label>
                        <label><input type="radio" name="csv-options" id="" value="Export a date range" onClick={()=>setShow("daterange")}/> Export a date range</label>
                    </div>
                    {show==="daterange" && 
                    <div className="export-date-range" style={{display:'block'}}>
                        <div className="form-group">
                            <label> Start Date </label>
                            <input type="date" name="startDate" id="" className="form-control"/>
                        </div>
                        <div className="form-group">
                            <label> End Date </label>
                            <input type="date" name="endDate" id="" className="form-control"/>
                        </div>
                        
                    </div>
                    }
                    {show==="livedata" &&
                    <div className="dwnld-btn-cont">
                        <Link to="#" className="btn btn-success dwnld-btn">
                        
                        <DownWhiteIcn className="mr-2"/> Download CSV file</Link>
                    </div>
                    }
                </div>
                
            </div>
        </div>
    </div>
  )
}

export default ExportCsv