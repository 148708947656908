import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const ViewKey = ({setShowVkey}) => {
    const[show,setShow]=useState("livedata")
  return (
    <div className="modal fade show" id="viewkeyModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-modal="true" style={{display:'block'}}>
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="close-modal">
                    <button className="close" type="button" data-dismiss="modal" aria-label="Close" onClick={()=>setShowVkey(false)}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <h3>ViewKeys</h3>
                    <p> https://columbustowing.us/ </p>
                    <div className="export-csv-options">
                        <label className="mr-2">
                        <input type="radio" name="viewkey-options" id="" value="Export Live Data" checked onClick={()=>setShow("livedata")}/> Export live data</label>
                        <label><input type="radio" name="viewkey-options" id="" value="Export a date range" onClick={()=>setShow("daterange")}/> Export a date range</label>
                    </div>
                    {show==="daterange" &&
                    <div className="export-date-range2" style={{display:'block'}}>
                        <div className="form-group">
                            <label> ViewKey </label>
                            <input type="text" id="" className="form-control" value="https://app.keyword.com/projects/"/>
                            <div className="text-right"><Link to="#" className="text-primary">Copy</Link></div>
                        </div>
                        
                        
                    </div>
                    }
                    {show ==="livedata" && 
                    <div className="cancel-btn-cont">
                        <Link to="#" className="btn btn-danger" data-dismiss="modal" onClick={()=>setShowVkey(false)}>Cancel</Link>
                    </div>
                    }
                </div>
                
            </div>
        </div>
    </div>
  )
}

export default ViewKey