import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import AddKeywords from "./Pages/AddKeywords";
import Dashboard from "./Pages/Dashboard";
import Project from "./Pages/Project";
import Settingg from "./Pages/Settingg";


function App() {
  let routes=[
    {path:"/addkeywords",element:<AddKeywords/>},
    {path:"/",element:<Dashboard/>},
    {path:"/setting",element:<Settingg/>},
    {path:"/project",element:<Project/>},

    { path: "*", element: <Navigate to="/" replace /> },
  ]
  return(
    <BrowserRouter>
    
      <Routes>
        {routes?.map((route, index) => (
          <Route key={index} {...route} />
        ))}
      </Routes>
    
  </BrowserRouter>
    
    
  )
 
}

export default App;
