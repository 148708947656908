import React, { useRef, useState } from "react";
import Layout from "../../assests/components/Layout/Layout";
import Table from "./Table";
import usa_flag_icn from "../../assests/images/icons/usa_flag_icn.png";
import {
  ActionIcn,
  ArchiveIcn,
  DownIcn,
  DownIcn3,
  EditIcn,
  ExportBtn,
  InfoIcn1,
  InfoIcn2,
  InfoIcn3,
  MonitorIcn,
  RankUp,
  ShareBtn,
  SortIcn,
  ViewkeyIcn,
} from "../../assests/images/icons";
import { graph, projectKewordTable } from "../../assests/mockdata/Array";
import Topbar from "../../assests/components/Layout/Topbar";
import { Link } from "react-router-dom";
import { AddIcn } from "../../assests/images";
import ViewkeySetting from "./ViewkeySetting";
import EditDropdown from "./EditDropdown";
import EditProject from "../Dashboard/EditProject";
import ArchiveProject from "../Dashboard/ArchiveProject";
import ExportCsv from "../Dashboard/ExportCsv";
import LineChart2 from "../Dashboard/LineChart2";
import LineChart from "../Dashboard/LineChart";
import RankingModal from "./RankingModal";

const Project = () => {
  const ref = useRef(null);
  const [showExport, setShowExport] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [showAction, setShowAction] = useState(false);
  const [showTab, setShowTab] = useState("keyword");
  const [showFilter, setShowFilter] = useState(false);
  const [showViewkey, setShowViewkey] = useState(false);

  const [showTag, setShowTag] = useState(" ");

  const [showFormat, setShowFormat] = useState(false);
  const [showSerp, setShowSerp] = useState(false);
  const [showGraph, setShowGraph] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [archive, setArchive] = useState(false);
  const [showCsv, setShowCsv] = useState(false);
  const [showRanking, setShowRanking] = useState(false);
  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref?.current?.contains(event.target)) {
        setShowTag(" ");
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [ref]);

  return (
    <Layout ref={ref}>
      <div id="content">
        <Topbar
          name={"columbustowing.us"}
          subname={"Last Updated: 17 hours ago (Nov 3rd, 2022)"}
        />
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card shadow-sm mb-4">
                <div className="card-body">
                  <div className="project-card-header">
                    <Link to="#" className="text-dark">
                      <i className="fas fa-globe-americas"></i>{" "}
                      towingbaltimore.us
                    </Link>
                    <div className="action-control-cont ">
                      <div className="actoin-btns-cont">
                        <div className="dropdown no-arrow">
                          <Link
                            to="#"
                            className="btn btn-outline-dark dropdown-toggle"
                            role="button"
                            onClick={() => setShowExport(!showExport)}
                            data-toggle="dropdown"
                          >
                            <ExportBtn className="mr-1" /> Export
                          </Link>
                          {showExport && (
                            <div
                              className="dropdown-menu dropdown-menu-right shadow show "
                              aria-labelledby="homeActionDropdown1"
                            >
                              <Link className="dropdown-item" to="#">
                                <DownIcn3 className="mr-1" />
                                Text
                              </Link>
                              <Link className="dropdown-item" to="#">
                                <DownIcn3 className="mr-1" />
                                PDF
                              </Link>
                              <Link
                                className="dropdown-item"
                                to="#"
                                data-toggle="modal"
                                data-target="#csvModal"
                              >
                                <DownIcn3 className="mr-1" />
                                CSV
                              </Link>
                            </div>
                          )}
                        </div>
                        <div className="dropdown no-arrow">
                          <Link
                            to="#"
                            className="btn btn-primary dropdown-toggle"
                            role="button"
                            onClick={() => setShowShare(!showShare)}
                            data-toggle="dropdown"
                          >
                            <ShareBtn className="mr-1" />
                            Share
                          </Link>
                          {showShare && (
                            <div
                              className="dropdown-menu dropdown-menu-right shadow  show p-3 dropdown-menu-lg "
                              aria-labelledby="homeActionDropdown1"
                            >
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-link mr-2"></i> ViewKey
                                  Link
                                </label>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    aria-label="Copy"
                                    aria-describedby="inputGroup-sizing-default"
                                    value="https://app.keyword.com"
                                  />
                                  <div className="input-group-prepend">
                                    <button
                                      className="btn btn-primary"
                                      type="button"
                                    >
                                      Copy
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group">
                                <label>
                                  <i className="fas fa-dice-six mr-2"></i>{" "}
                                  Embedded Code
                                </label>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    aria-label="Copy"
                                    aria-describedby="inputGroup-sizing-default"
                                    value="https://app.keyword.com"
                                  />
                                  <div className="input-group-prepend">
                                    <button
                                      className="btn btn-primary"
                                      type="button"
                                    >
                                      Copy
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <Link to="/addkeywords" className="btn btn-success">
                          <AddIcn className="mr-1" /> Add Keywords
                        </Link>
                      </div>
                      <div className="dropdown no-arrow">
                        <Link
                          to="#"
                          id="homeActionDropdown1"
                          className="dropdown-toggle"
                          role="button"
                          onClick={() => setShowAction(!showAction)}
                          data-toggle="dropdown"
                        >
                          <ActionIcn />
                        </Link>
                        {showAction && (
                          <div
                            className="dropdown-menu dropdown-menu-right shadow show "
                            aria-labelledby="homeActionDropdown1"
                          >
                            <Link
                              className="dropdown-item edit-project-btn"
                              onClick={() => setShowEdit(!showEdit)}
                              to="#"
                            >
                              <EditIcn className="mr-1" />
                              Edit Project
                            </Link>
                            <Link
                              className="dropdown-item"
                              to="#"
                              data-toggle="modal"
                              onClick={() => setShowViewkey(!showViewkey)}
                              data-target="#viewkeySettingsModal"
                            >
                              <ViewkeyIcn className="mr-1" />
                              Viewkey Project Settings
                            </Link>
                            <div className="dropdown-divider"></div>
                            <h6 className="pl-4">Remove</h6>
                            <Link
                              className="dropdown-item"
                              to="#"
                              data-toggle="modal"
                              onClick={() => setArchive(!archive)}
                              data-target="#archiveModal"
                            >
                              <ArchiveIcn className="mr-1" />
                              Archive Project
                            </Link>
                            <Link
                              className="dropdown-item"
                              to="#"
                              data-toggle="modal"
                              data-target="#archiveModal"
                            >
                              <ArchiveIcn className="mr-1" />
                              Delete Project
                            </Link>
                            <div className="dropdown-divider"></div>
                            <h6 className="pl-4">Account Wide</h6>
                            <Link className="dropdown-item" to="#">
                              <DownIcn3 className="mr-1" />
                              Viewkey Brand Settings
                            </Link>
                            <Link
                              className="dropdown-item"
                              to="#"
                              onClick={() => setShowCsv(!showCsv)}
                              data-toggle="modal"
                              data-target="#csvModal"
                            >
                              <DownIcn3 className="mr-1" />
                              Table Settings
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-lg-12">
                      <div className="title-header">
                        <Link to="#">towingbaltimore.us</Link>
                        <span className="keywords-count">
                          85 Total Keywords{" "}
                        </span>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6">
                      <div className="info-gadgets-cont grid-3">
                        <div className="info-gadget theme-orng">
                          <div className="gadget-body">
                            <div className="icn-cont">
                              <InfoIcn1 />
                            </div>
                            <div className="counts">649</div>
                            <div className="count-info">Keywords Up</div>
                          </div>
                          <div className="gadget-footer">
                            <Link to="#" className="gadget-btn">
                              Since Start
                            </Link>
                          </div>
                        </div>
                        <div className="info-gadget theme-purple">
                          <div className="gadget-body">
                            <div className="icn-cont">
                              <InfoIcn2 />
                              <Link to="#" className="dwnld">
                                <DownIcn />
                              </Link>
                            </div>
                            <div className="counts">989 / 2433</div>
                            <div className="count-info">In Top 30</div>
                          </div>
                          <div className="gadget-footer">
                            <a to="#" className="gadget-btn">
                              -238 since start
                            </a>
                          </div>
                        </div>
                        <div className="info-gadget theme-green">
                          <div className="gadget-body">
                            <div className="icn-cont">
                              <InfoIcn3 />
                              <Link to="#" className="dwnld">
                                <DownIcn />
                              </Link>
                            </div>
                            <div className="counts">354/2433</div>
                            <div className="count-info">In Top 3</div>
                          </div>
                          <div className="gadget-footer">
                            <Link to="#" className="gadget-btn">
                              -165 since start{" "}
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="info-gadgets-cont grid-3 mt-3">
                        <div className="info-gadget theme-orng">
                          <div className="gadget-body">
                            <div className="icn-cont">
                              <InfoIcn1 />
                            </div>
                            <div className="counts">649</div>
                            <div className="count-info">Keywords Up</div>
                          </div>
                          <div className="gadget-footer">
                            <Link to="#" className="gadget-btn">
                              Since Start
                            </Link>
                          </div>
                        </div>
                        <div className="info-gadget theme-purple">
                          <div className="gadget-body">
                            <div className="icn-cont">
                              <InfoIcn2 />
                              <Link to="#" className="dwnld">
                                <DownIcn />
                              </Link>
                            </div>
                            <div className="counts">989 / 2433</div>
                            <div className="count-info">In Top 30</div>
                          </div>
                          <div className="gadget-footer">
                            <Link to="#" className="gadget-btn">
                              -238 since start
                            </Link>
                          </div>
                        </div>
                        <div className="info-gadget theme-green">
                          <div className="gadget-body">
                            <div className="icn-cont">
                              <InfoIcn3 />
                              <Link to="#" className="dwnld">
                                <DownIcn />
                              </Link>
                            </div>
                            <div className="counts">354/2433</div>
                            <div className="count-info">In Top 3</div>
                          </div>
                          <div className="gadget-footer">
                            <Link to="#" className="gadget-btn">
                              -165 since start{" "}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6">
                      <div className="chart-area">
                        <LineChart className="myAreaChart" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Content Row --> */}
          <div className="dashboard-tabs project-tabs">
            <ul className="nav nav-tabs">
              <li className="active mr-2">
                <Link
                  className={showTab === "keyword" ? "active" : ""}
                  data-toggle="tab"
                  onClick={() => setShowTab("keyword")}
                  to="#home"
                >
                  Keywords
                </Link>
              </li>
              <li>
                <Link
                  data-toggle="tab"
                  className={`${
                    showTab === "voice" ? "active" : ""
                  }`}
                  onClick={() => setShowTab("voice")}
                  to="#projects-table"
                >
                  Share of Voice
                </Link>
              </li>

              <li className="tab-right-controls ml-auto">
                <div className="tabs-controls">
                  <div className="tab-search ml-4">
                    <div className="tab-search-box">
                      <div className="search-box">
                        <input type="text" placeholder="search" />
                        <button className="btn search-btn p-0">
                          <i className="fas fa-search fa-sm"></i>
                        </button>
                      </div>
                    </div>
                    <Link to="#" className="text-dark tab-search-toggle mr-2">
                      <i className="fas fa-search fa-sm"></i>
                    </Link>
                  </div>

                  <div className="dropdown no-arrow">
                    <Link
                      className="dropdown-toggle"
                      id="sortDropDown"
                      role="button"
                      data-toggle="dropdown"
                      onClick={() => setShowFilter(!showFilter)}
                    >
                      <SortIcn />
                    </Link>
                    {showFilter && (
                      <div
                        className="dropdown-menu dropdown-menu-right shadow show dropdown-menu-lg"
                        aria-labelledby="sortDropDown"
                      >
                        <div className="form-group">
                          <label>Show</label>
                          <select name="show" id="" className="form-control">
                            <option value="All Keywords">All Keywords</option>
                            <option>Favorites</option>
                            <option>Non-Favorites</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label>Search Engine</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Everything"
                          />
                        </div>
                        <div className="form-group">
                          <label>Change Report Date</label>
                          <input type="date" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Tags</label>
                          <select name="show" id="" className="form-control">
                            <option value="Everything">Everything</option>
                            <option>Google Organic (Everything)</option>
                            <option>Google Organic (Desktop)</option>
                            <option>Google Organic (Mobile)</option>
                            <option>Google Maps</option>
                            <option>Youtube</option>
                          </select>
                        </div>
                        <Link to="#" className="btn btn-success">
                          Apply
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </li>
            </ul>

            <div className="tab-content">
              {showTab === "keyword" && (
                <div id="home" className="tab-pane in active">
                  <div className="keywrods-action mb-3">
                    <div className="keywords-action-dropdowns">
                      {/* <!-- Edit/Update URL --> */}
                      <div className="dropdown no-arrow">
                        <Link
                          className="dropdown-toggle"
                          id="editDropDown"
                          role="button"
                          onClick={() => setShowTag("dropdown")}
                          data-toggle="dropdown"
                        >
                          <i className="far fa-edit"></i>
                        </Link>
                        {showTag === "dropdown" && <EditDropdown />}
                      </div>
                      {/* <!-- Search or Create Tag --> */}
                      <div className="dropdown no-arrow">
                        <Link
                          className="dropdown-toggle"
                          id="tagsDropDown"
                          role="button"
                          onClick={() => setShowTag("tag")}
                          data-toggle="dropdown"
                        >
                          <i className="fas fa-tag"></i>
                        </Link>
                        {showTag === "tag" && (
                          <div
                            className="dropdown-menu dropdown-menu-right shadow show dropdown-menu-lg"
                            aria-labelledby="tagsDropDown"
                          >
                            <h4>Search Tag or Create New Tag:</h4>
                            <div className="form-group">
                              <label>Search Location optional</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Start Typing"
                              />
                            </div>
                            <div className="form-group">
                              <label>
                                <input type="checkbox" /> Tag Name
                              </label>
                            </div>

                            <Link to="#" className="btn btn-success btn-block">
                              Apply
                            </Link>
                          </div>
                        )}
                      </div>
                      {/* <!-- Redo --> */}
                      <div className="dropdown no-arrow">
                        <Link
                          className="dropdown-toggle"
                          id="tagsDropDown"
                          onClick={() => setShowTag("redo")}
                          role="button"
                          data-toggle="dropdown"
                        >
                          <i className="fas fa-redo-alt"></i>
                        </Link>
                        {showTag === "redo" && (
                          <div
                            className="dropdown-menu dropdown-menu-right shadow show dropdown-menu-lg"
                            aria-labelledby="tagsDropDown"
                          >
                            <h4>Redo</h4>
                            <div className="form-group">
                              <label>Search Location optional</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Start Typing"
                              />
                            </div>
                            <div className="form-group">
                              <label>
                                <input type="checkbox" /> Tag Name
                              </label>
                            </div>

                            <Link to="#" className="btn btn-success btn-block">
                              Apply
                            </Link>
                          </div>
                        )}
                      </div>
                      {/* <!-- Graph --> */}
                      <Link
                        className="graph-toggle graph-show"
                        onClick={() => setShowGraph(!showGraph)}
                        role="button"
                      >
                        <i className="fas fa-chart-line"></i>
                      </Link>

                      {/* <!-- Download --> */}
                      <div className="dropdown no-arrow">
                        <Link
                          className="dropdown-toggle"
                          id="tagsDropDown"
                          role="button"
                          onClick={() => setShowTag("download")}
                          data-toggle="dropdown"
                        >
                          <i class="fas fa-download"></i>
                        </Link>
                        {showTag === "download" && (
                          <div
                            className="dropdown-menu dropdown-menu-right shadow show dropdown-menu-lg"
                            aria-labelledby="tagsDropDown"
                          >
                            <h4>Download</h4>
                            <div className="form-group">
                              <label>Search Location optional</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Start Typing"
                              />
                            </div>
                            <div className="form-group">
                              <label>
                                <input type="checkbox" /> Tag Name
                              </label>
                            </div>

                            <Link to="#" className="btn btn-success btn-block">
                              Apply
                            </Link>
                          </div>
                        )}
                      </div>
                      {/* <!-- Move Keywords --> */}
                      <div className="dropdown no-arrow">
                        <a
                          className="dropdown-toggle"
                          id="tagsDropDown"
                          role="button"
                          onClick={() => setShowTag("move")}
                          data-toggle="dropdown"
                        >
                          <i className="fas fa-pencil-alt"></i>
                        </a>
                        {showTag === "move" && (
                          <div
                            className="dropdown-menu dropdown-menu-right shadow show dropdown-menu-lg"
                            aria-labelledby="tagsDropDown"
                          >
                            <h4>Move Keywords</h4>
                            <div className="form-group">
                              <label>
                                Select which project or group to move your
                                keywords into. Your keyword history will be
                                retained.
                              </label>
                              <select name="" id="" className="form-control">
                                <option>Select project or group</option>
                              </select>
                            </div>
                            <div className="form-group text-field-icn">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search"
                              />
                            </div>

                            <Link to="#" className="btn btn-success">
                              Save
                            </Link>
                          </div>
                        )}
                      </div>

                      {/* <!-- Remove --> */}
                      <Link to="#">
                        <i className="fas fa-trash-alt"></i>
                      </Link>
                      {/* <!-- Duplicate Keywords --> */}
                      <div className="dropdown no-arrow">
                        <a
                          className="dropdown-toggle"
                          id="tagsDropDown"
                          role="button"
                          onClick={() => setShowTag("duplicate")}
                          data-toggle="dropdown"
                        >
                          <i className="far fa-clone"></i>
                        </a>
                        {showTag === "duplicate" && (
                          <div
                            className="dropdown-menu dropdown-menu-right shadow show dropdown-menu-lg overflow-visible"
                            aria-labelledby="tagsDropDown"
                          >
                            <h4>Duplicate Keywords</h4>
                            <div className="form-group">
                              <label>
                                Select which project or group to duplicate your
                                keywords into.
                              </label>
                              <select name="" id="" className="form-control">
                                <option>Select project or group</option>
                              </select>
                            </div>
                            <div className="form-group text-field-icn">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search"
                              />
                            </div>
                            <div className="form-group">
                              <div className="url-field">
                                <div className="prefix">http(s)://</div>
                                <input type="text" placeholder="Domain.com" />
                                <div className="custom-dropdown">
                                  <Link
                                    className="custom-dropdown-toggle"
                                    role="button"
                                  >
                                    *.domain.com/*
                                  </Link>
                                  <div className="custom-dropdown-menu shadow fade">
                                    <div className="close-custom-dropdown">
                                      &times;
                                    </div>
                                    <h5>
                                      *.domain.com/*{" "}
                                      <span>
                                        <i className="far fa-question-circle"></i>
                                      </span>
                                    </h5>
                                    <p>All subdomains and all pages</p>
                                    <div className="dropdown-divider"></div>
                                    <h5>
                                      URL
                                      <span>
                                        <i className="far fa-question-circle"></i>
                                      </span>
                                    </h5>
                                    <p>Exact URL</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <Link to="#" className="btn btn-success">
                              Save
                            </Link>
                          </div>
                        )}
                      </div>
                      {/* <!-- Star --> */}
                      <Link to="#">
                        <i className="far fa-star"></i>
                      </Link>
                    </div>
                  </div>
                  {showGraph && (
                    <div className="line-graph-cont graph-show">
                      <div className="graph-header-actions">
                        <div className="serp-controls">
                          <div className="serp-history-toggle-cont">
                            Competition{" "}
                            <a
                              to="#"
                              onClick={() => setShowSerp(!showSerp)}
                              className="btn btn-outline-dark serp-history-toggle"
                            >
                              SERP History
                            </a>
                          </div>
                          <div className="graphtoggle-btn-cont">
                            <Link
                              className="graph-toggle btn btn-outline-dark"
                              onClick={() => setShowGraph(!showGraph)}
                              role="button"
                            >
                              <i className="fas fa-chart-line"></i>
                            </Link>
                          </div>
                          <div className="search-graph-cont">
                            <input type="text" name="" />
                            <span className="graph-icn">
                              <i className="fas fa-chart-line"></i>
                            </span>
                          </div>
                        </div>
                        <div className="graph_actions ml-auto">
                          <Link
                            to="#"
                            className="btn btn-outline-primary reset-zoom"
                          >
                            Reset Zoom
                          </Link>
                          <div className="dropdown">
                            <Link
                              className="dropdown-toggle"
                              id="tagsDropDown"
                              role="button"
                              onClick={() => setShowFormat(!showFormat)}
                              data-toggle="dropdown"
                            >
                              <i className="fas fa-download"></i>
                            </Link>
                            {showFormat && (
                              <div
                                className="dropdown-menu dropdown-menu-right shadow show "
                                aria-labelledby="homeActionDropdown1"
                              >
                                <Link className="dropdown-item" to="#">
                                  <DownIcn3 className="mr-1" />
                                  Download in .JPEG
                                </Link>
                                <Link className="dropdown-item" to="#">
                                  <DownIcn3 className="mr-1" />
                                  Download in .PNG
                                </Link>
                                <Link className="dropdown-item" to="#">
                                  <DownIcn3 className="mr-1" />
                                  Download in .PDF
                                </Link>
                                <Link className="dropdown-item" to="#">
                                  <DownIcn3 className="mr-1" />
                                  Download in .SVG
                                </Link>
                                <Link className="dropdown-item" to="#">
                                  <DownIcn3 className="mr-1" />
                                  Print Chart
                                </Link>
                              </div>
                            )}
                          </div>
                          <Link
                            to="#"
                            className="close-graph"
                            onClick={() => setShowGraph(false)}
                          >
                            &times;
                          </Link>
                        </div>
                      </div>
                      {showSerp && (
                        <div className="serp-history-popup ">
                          <div className="close-serp">&times;</div>
                          <div className="header-title">
                            <h3>SERP History</h3>
                            <p>Keyword: san francisco ups towing</p>
                          </div>
                          <div className="select-date-fields">
                            <div className="start-date">
                              <label>Start Date</label>
                              <input
                                type="date"
                                name=""
                                id=""
                                className="form-control"
                              />
                            </div>
                            <div className="end-date">
                              <label>End Date</label>
                              <input
                                type="date"
                                name=""
                                id=""
                                className="form-control"
                              />
                            </div>
                            <Link to="#" className="btn btn-primary">
                              Apply Date Range
                            </Link>
                            <Link to="#" className="btn btn-primary">
                              Export
                            </Link>
                          </div>
                          <div className="serp-data-list-cont">
                            {graph.map((val) => {
                              return (
                                <div className="serp-data">
                                  <div className="date-cont">{val.title}</div>
                                  <div className="websites-listing">
                                    <ul>
                                      {val.list.map(({ img, web }) => {
                                        return (
                                          <li>
                                            <span className="nos">1</span>
                                            <span className="fav-icn">
                                              <img src={img} alt="" />
                                            </span>
                                            <span className="web-name">
                                              {web}
                                            </span>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                      <div className="graph-cont">
                        <LineChart2 className="myAreaChart2" />
                      </div>
                      <div className="graph-other-options">
                        <div className="view-options">
                          Views: <Link to="#">10 days</Link> -{" "}
                          <Link to="#">30 Days</Link> -{" "}
                          <Link to="#">60 Days</Link> -{" "}
                          <Link to="#">90 Days</Link> -{" "}
                          <Link to="#">180 Days</Link> -{" "}
                          <Link to="#">365 Days</Link> -{" "}
                          <Link to="#">All Time</Link>
                        </div>
                        <div className="show-rankings">
                          <Link
                            to="#"
                            data-toggle="modal"
                            onClick={() => setShowRanking(!showRanking)}
                            data-target="#show-rankings"
                          >
                            Show Ranking URLs
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="table-responsive">
                    <table className="table table-striped table-bordered keywords-table">
                      <thead>
                        <tr>
                          <th>
                            <input type="checkbox" name="" id="" />
                          </th>
                          <th>Keyword</th>
                          <th>Start</th>
                          <th>Page</th>
                          <th>Best</th>
                          <th>Rank</th>
                          <th>1 Day</th>
                          <th>7 Days</th>
                          <th>30 Days</th>
                          <th>Search Vol.</th>
                          <th>Search Location</th>
                          <th>URL</th>
                        </tr>
                      </thead>
                      <tbody>
                        {projectKewordTable.map(
                          ({
                            keyword,
                            start,
                            page,
                            best,
                            rank,
                            Oday,
                            Sday,
                            Tday,
                            searchvol,
                            loc,
                            url,
                          }) => {
                            return (
                              <tr>
                                <td>
                                  <input type="checkbox" name="" id="" />
                                </td>
                                <td>
                                  <div className="keyword-info">
                                    <div className="keyword-txt">
                                      <span className="starred">
                                        <i className="fas fa-star"></i>
                                      </span>
                                      <span className="flag-icn">
                                        <img src={usa_flag_icn} />
                                      </span>
                                      <span className="monitor-icn">
                                        <MonitorIcn />
                                      </span>
                                      {keyword}
                                    </div>

                                    <div className="hover-actions">
                                      <Link to="#" className="star-action">
                                        <i className="fas fa-star"></i>
                                      </Link>
                                      <Link to="#" className="graph-toggle">
                                        <i className="fas fa-chart-line"></i>
                                      </Link>
                                      <Link to="#" className="table-search">
                                        <i className="fas fa-search fa-sm"></i>
                                      </Link>
                                    </div>
                                  </div>
                                </td>
                                <td>{start}</td>
                                <td>{page}</td>
                                <td>{best}</td>
                                <td>{rank}</td>
                                <td>
                                  <div className="d-flex justify-content-between">
                                    <span>
                                      <RankUp /> {Oday}
                                    </span>
                                    <span>{Oday}</span>
                                  </div>
                                </td>
                                <td>{Sday}</td>
                                <td>
                                  <div className="d-flex justify-content-between">
                                    <span>
                                      <RankUp /> {Tday}
                                    </span>
                                    <span>{Tday}</span>
                                  </div>
                                </td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
              {showTab === "voice" && <Table />}
            </div>
          </div>
        </div>
        {showViewkey && <ViewkeySetting setShowViewkey={setShowViewkey} />}
        {showEdit && <EditProject setShowEdit={setShowEdit} />}
        {archive && <ArchiveProject setArchive={setArchive} />}
        {showCsv && <ExportCsv setShowCsv={setShowCsv} />}
        {showRanking && <RankingModal setShowRanking={setShowRanking} />}
      </div>
    </Layout>
  );
};

export default Project;
