import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import vk_img1 from "../../assests/images/vk_img1.jpg"
import vk_img2 from "../../assests/images/vk_img2.jpg"
import company_logo from "../../assests/images/company_logo.jpg"
import { CompanyIcn, LinkIcn, LogoIcn, NoteIcn, SaveIcn } from '../../assests/images/icons'
import Newproject from './Newproject'
const ViewKey = () => {
    const[showSidebar,setShowSidebar]=useState(false)
  return (
    <div id="viewkey-brand" className="tab-pane in active">
                            <div className="row">
                                <div className="col-12 col-lg-4">
                                    <div className="viewkey-settings-cont">
                                        <h2>Viewkey Brand Settings</h2>
                                        <div className="card card-bg-success">
                                            <div className="card-body">
                                                Customize your ViewKey to fit your brand for all the projects in your account.
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="ios-switch">
                                                <div className="cl-toggle-switch">
                                                    <label className="cl-switch">
                                                        <input type="checkbox"/>
                                                        <span></span>
                                                    </label>
                                                </div>
                                                <span>Hide "Verified by Keyword.com"</span>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="ios-switch">
                                                <div className="cl-toggle-switch">
                                                    <label className="cl-switch">
                                                        <input type="checkbox"/>
                                                        <span></span>
                                                    </label>
                                                </div>
                                                <span>Use Whitelabel Domain (seranktracker.com)</span>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="ios-switch">
                                                <div className="cl-toggle-switch">
                                                    <label className="cl-switch">
                                                        <input type="checkbox"/>
                                                        <span></span>
                                                    </label>
                                                </div>
                                                <span>Display Company Information in ViewKeys</span>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="ios-switch">
                                                <div className="cl-toggle-switch">
                                                    <label className="cl-switch">
                                                        <input type="checkbox"/>
                                                        <span></span>
                                                    </label>
                                                </div>
                                                <span>Custom Subdomain</span>
                                            </div>
                                        </div>
                                       
                                       
                                        <div className="card mb-4">
                                            <div className="card-body">
                                                <div className="form-group">
                                                    <label><CompanyIcn/> Company Name</label>
                                                    <input type="text" className="form-control" placeholder="Your Company Name"/>
                                                </div>
                                                <div className="form-group">
                                                    <label><LinkIcn/> Company URL Link</label>
                                                    <input type="text" className="form-control" placeholder="support@keyword.com"/>
                                                </div>
                                                <div className="form-group">
                                                    <label><LogoIcn/> Company Logo URL</label>
                                                    <input type="text" className="form-control" placeholder="https://app.keyword.com/images/logo.png"/>
                                                </div>
                                                <div className="form-group">
                                                    <label><NoteIcn/> Company Description</label>
                                                    <textarea name="" id="" cols="30" rows="5" className="form-control" placeholder="Keyword.com Rank Tracking Service - Whitelabel Reports"></textarea>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <Link to="#" className="btn btn-success add-project-btn" onClick={()=>setShowSidebar(!showSidebar)}>
                                           <SaveIcn className="mr-1"/>Save Changes</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-8">
                                    <div className="viewkey-keyword-report-cont">
                                        <div className="title-cont">
                                            <h2>Ranking Report Data Verified By Keyword.com</h2>
                                            <Link to="#" className="btn btn-outline-primary">Leanr More</Link>
                                        </div>
                                        <div className="report-banner mb-4">
                                            <div className="company-logo">
                                                <img src={company_logo} alt=""/>
                                            </div>
                                            <div className="company-info">
                                                <h3>Company Name </h3>
                                                <p>
                                                    Company description - say hi to your customers and prospects!
                                                </p>
                                            </div>
                                        </div>
                                        <h4>Group Name </h4>
                                        <p>Last Refreshed:11 hours ago (Nov 20th, 2018)</p>
                                        <div className="vk-img">
                                            <img src={vk_img1} alt=""/>
                                        </div>
                                        <div className="vk-img mt-4">
                                            <img src={vk_img2} alt=""/>
                                        </div>
                                    </div>  
                                </div>
                            </div>
                            {showSidebar && <Newproject setShowSidebar={setShowSidebar}/>}
                        </div>
  )
}

export default ViewKey