import { DownIcn, InfoIcn1, InfoIcn2, InfoIcn3, InfoIcn4, InfoIcn5, InfoIcn6, RankUp } from "../images/icons";
import towing_san_favicon from "../../assests/images/towing_san_favicon.png"
export const cards=[
    {
        id:1,
        theme:"theme-orng",
        img:<InfoIcn1/>,
        counts:"649",
        down:"",
        text:"Keywords Up ",
        footer:"Since Start"

    },
    {
        id:2,
        theme:"theme-purple",
        img:<InfoIcn2/>,
        down:<DownIcn/>,
        counts:"989 / 2433 ",
        text:"In Top 30  ",
        footer:"-238 since start"

    },
    {
        id:1,
        theme:"theme-green",
        img:<InfoIcn3/>,
        down:<DownIcn/>,
        counts:"354/2433",
        text:"In Top 3 ",
        footer:"-165 since start"

    },
    {
        id:1,
        theme:"theme-red",
        img:<InfoIcn4/>,
        down:<DownIcn/>,
        counts:" 682 / 2433",
        text:"In Top 10 ",
        footer:"-205 since start"

    },
    {
        id:1,
        theme:"theme-drkgreen",
        img:<InfoIcn5/>,
        down:<DownIcn/>,
        counts:" 1618 / 2433",
        text:"In Top 100",
        footer:"-259 since start"

    },
    {
        id:1,
        theme:"theme-blue",
        img:<InfoIcn6/>,
        down:<DownIcn/>,
        counts:" 1490 / 2433  ",
        text:"In Top 80 ",
        footer:"-271 since start"

    },
]
export const setting=[
    {
        id:1,
        title:"Account"
    },
    {
        id:1,
        title:"Billing & My Plan"
    },
    {
        id:1,
        title:"Shared Access"
    },
    {
        id:1,
        title:"Viewkey Brand"
    },
    {
        id:1,
        title:"Table"
    },
]
export const projectColumn=[
    {
        id:1,
        label:"Starting Rank",
        type:"checkbox",
        name:""
    },
    {
        id:1,
        label:"Best Rank",
        type:"checkbox",
        name:""
    },
    {
        id:1,
        label:"1 Day Change",
        type:"checkbox",
        name:""
    },
    {
        id:1,
        label:"30 Day Change",
        type:"checkbox",
        name:""
    },
    {
        id:1,
        label:"Search Volume",
        type:"checkbox",
        name:""
    },
    {
        id:1,
        label:"Tags ",
        type:"checkbox",
        name:""
    },
    {
        id:1,
        label:"Search Location",
        type:"checkbox",
        name:""
    },
]
export const HidekeyColumn=[
    {
        id:1,
        label:"Google Ranking Page",
        type:"checkbox",
        name:""
    },
    {
        id:1,
        label:"Google Rank",
        type:"checkbox",
        name:""
    },
    {
        id:1,
        label:"7 Day Change",
        type:"checkbox",
        name:""
    },
    {
        id:1,
        label:"Lifetime Change (vs Start Rank)",
        type:"checkbox",
        name:""
    },
    {
        id:1,
        label:"Search Results",
        type:"checkbox",
        name:""
    },
    {
        id:1,
        label:"Date Added ",
        type:"checkbox",
        name:""
    },
    {
        id:1,
        label:"GMB",
        type:"checkbox",
        name:""
    },
]
export const billingTable=[
    {
        id:1,
        date:"2022-09-05",
        amount:"$2,279.00",
        download:"PDF"
    },
    {
        id:1,
        date:"2022-09-05",
        amount:"$2,279.00",
        download:"PDF"
    },
    {
        id:1,
        date:"2022-09-05",
        amount:"$2,279.00",
        download:"PDF"
    },
]
export const projectTable=[
    {
        id:1,
        rank:"30",
        share:"16",
        percent:"0",
        domain:"16"
    },
    {
        id:1,
        rank:"30",
        share:"16",
        percent:"0",
        domain:"16"
    },
    
    {
        id:1,
        rank:"30",
        share:"16",
        percent:"0",
        domain:"16"
    },
    
    {
        id:1,
        rank:"30",
        share:"16",
        percent:"0",
        domain:"16"
    },
    
    {
        id:1,
        rank:"30",
        share:"16",
        percent:"0",
        domain:"16"
    },
    
    {
        id:1,
        rank:"30",
        share:"16",
        percent:"0",
        domain:"16"
    },
    
    {
        id:1,
        rank:"30",
        share:"16",
        percent:"0",
        domain:"16"
    },
    
]
export const projectKewordTable=[
    {
        id:1,
        keyword:"riverside towing for uber cars",
        start:"30",
        page:"16",
        best:"0",
        rank:"16",
        Oday:"2",
        Sday:"0",
        Tday:"2",
        searchvol:"",
        loc:"",
        url:""

    },
    {
        id:1,
        keyword:"riverside towing for uber cars",
        start:"30",
        page:"16",
        best:"0",
        rank:"16",
        Oday:"2",
        Sday:"0",
        Tday:"2",
        searchvol:"",
        loc:"",
        url:""

    },
]
export const graph=[
    {
        id:1,
        title:"Oct 23, 2022",
        list:[
            {
                img:towing_san_favicon,
                web:"https://towing-sanfrancisco.us/"
            },
            {
                img:towing_san_favicon,
                web:"https://towing-sanfrancisco.us/"
            },
            {
                img:towing_san_favicon,
                web:"https://towing-sanfrancisco.us/"
            },
            {
                img:towing_san_favicon,
                web:"https://towing-sanfrancisco.us/"
            },
        ]
    },
    {
        id:1,
        title:"Oct 23, 2022",
        list:[
            {
                img:towing_san_favicon,
                web:"https://towing-sanfrancisco.us/"
            },
            {
                img:towing_san_favicon,
                web:"https://towing-sanfrancisco.us/"
            },
            {
                img:towing_san_favicon,
                web:"https://towing-sanfrancisco.us/"
            },
            {
                img:towing_san_favicon,
                web:"https://towing-sanfrancisco.us/"
            },
        ]
    },
    {
        id:1,
        title:"Oct 23, 2022",
        list:[
            {
                img:towing_san_favicon,
                web:"https://towing-sanfrancisco.us/"
            },
            {
                img:towing_san_favicon,
                web:"https://towing-sanfrancisco.us/"
            },
            {
                img:towing_san_favicon,
                web:"https://towing-sanfrancisco.us/"
            },
            {
                img:towing_san_favicon,
                web:"https://towing-sanfrancisco.us/"
            },
        ]
    },
    {
        id:1,
        title:"Oct 23, 2022",
        list:[
            {
                img:towing_san_favicon,
                web:"https://towing-sanfrancisco.us/"
            },
            {
                img:towing_san_favicon,
                web:"https://towing-sanfrancisco.us/"
            },
            {
                img:towing_san_favicon,
                web:"https://towing-sanfrancisco.us/"
            },
            {
                img:towing_san_favicon,
                web:"https://towing-sanfrancisco.us/"
            },
        ]
    },
    {
        id:1,
        title:"Oct 23, 2022",
        list:[
            {
                img:towing_san_favicon,
                web:"https://towing-sanfrancisco.us/"
            },
            {
                img:towing_san_favicon,
                web:"https://towing-sanfrancisco.us/"
            },
            {
                img:towing_san_favicon,
                web:"https://towing-sanfrancisco.us/"
            },
            {
                img:towing_san_favicon,
                web:"https://towing-sanfrancisco.us/"
            },
        ]
    },
    {
        id:1,
        title:"Oct 23, 2022",
        list:[
            {
                img:towing_san_favicon,
                web:"https://towing-sanfrancisco.us/"
            },
            {
                img:towing_san_favicon,
                web:"https://towing-sanfrancisco.us/"
            },
            {
                img:towing_san_favicon,
                web:"https://towing-sanfrancisco.us/"
            },
            {
                img:towing_san_favicon,
                web:"https://towing-sanfrancisco.us/"
            },
        ]
    },
    {
        id:1,
        title:"Oct 23, 2022",
        list:[
            {
                img:towing_san_favicon,
                web:"https://towing-sanfrancisco.us/"
            },
            {
                img:towing_san_favicon,
                web:"https://towing-sanfrancisco.us/"
            },
            {
                img:towing_san_favicon,
                web:"https://towing-sanfrancisco.us/"
            },
            {
                img:towing_san_favicon,
                web:"https://towing-sanfrancisco.us/"
            },
        ]
    },
    {
        id:1,
        title:"Oct 23, 2022",
        list:[
            {
                img:towing_san_favicon,
                web:"https://towing-sanfrancisco.us/"
            },
            {
                img:towing_san_favicon,
                web:"https://towing-sanfrancisco.us/"
            },
            {
                img:towing_san_favicon,
                web:"https://towing-sanfrancisco.us/"
            },
            {
                img:towing_san_favicon,
                web:"https://towing-sanfrancisco.us/"
            },
        ]
    },
    {
        id:1,
        title:"Oct 23, 2022",
        list:[
            {
                img:towing_san_favicon,
                web:"https://towing-sanfrancisco.us/"
            },
            {
                img:towing_san_favicon,
                web:"https://towing-sanfrancisco.us/"
            },
            {
                img:towing_san_favicon,
                web:"https://towing-sanfrancisco.us/"
            },
            {
                img:towing_san_favicon,
                web:"https://towing-sanfrancisco.us/"
            },
        ]
    },
    {
        id:1,
        title:"Oct 23, 2022",
        list:[
            {
                img:towing_san_favicon,
                web:"https://towing-sanfrancisco.us/"
            },
            {
                img:towing_san_favicon,
                web:"https://towing-sanfrancisco.us/"
            },
            {
                img:towing_san_favicon,
                web:"https://towing-sanfrancisco.us/"
            },
            {
                img:towing_san_favicon,
                web:"https://towing-sanfrancisco.us/"
            },
        ]
    },
]
export const DashboardTable=[
    {
        id:1,
        project:"towingbaltimore.us",
        date:"2022-09-05",
        totalKeys:"16",
        top3:"0",
        top10:"16",
        img:RankUp,
        top80:"2",
        top30:"0",
        top100:"2",
       

    },
    {
        id:1,
        project:"towingbaltimore.us",
        date:"2022-09-05",
        totalKeys:"16",
        top3:"0",
        top10:"16",
        img:RankUp,
        top80:"2",
        top30:"0",
        top100:"2",
       

    },
    {
        id:1,
        project:"towingbaltimore.us",
        date:"2022-09-05",
        totalKeys:"16",
        top3:"0",
        top10:"16",
        img:RankUp,
        top80:"2",
        top30:"0",
        top100:"2",
       

    },
    {
        id:1,
        project:"towingbaltimore.us",
        date:"2022-09-05",
        totalKeys:"16",
        top3:"0",
        top10:"16",
        img:RankUp,
        top80:"2",
        top30:"0",
        top100:"2",
       

    },
    {
        id:1,
        project:"towingbaltimore.us",
        date:"2022-09-05",
        totalKeys:"16",
        top3:"0",
        top10:"16",
        img:RankUp,
        top80:"2",
        top30:"0",
        top100:"2",
       

    },
    {
        id:1,
        project:"towingbaltimore.us",
        date:"2022-09-05",
        totalKeys:"16",
        top3:"0",
        top10:"16",
        img:RankUp,
        top80:"2",
        top30:"0",
        top100:"2",
       

    },
    {
        id:1,
        project:"towingbaltimore.us",
        date:"2022-09-05",
        totalKeys:"16",
        top3:"0",
        top10:"16",
        img:RankUp,
        top80:"2",
        top30:"0",
        top100:"2",
       

    },
    {
        id:1,
        project:"towingbaltimore.us",
        date:"2022-09-05",
        totalKeys:"16",
        top3:"0",
        top10:"16",
        img:RankUp,
        top80:"2",
        top30:"0",
        top100:"2",
       

    },
]