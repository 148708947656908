import React, { useState } from 'react'
import Layout from '../../assests/components/Layout/Layout'
import Topbar from '../../assests/components/Layout/Topbar'
import { setting } from '../../assests/mockdata/Array'
import Account from './Account'
import Billing from './Billing'
import BillingModal from './BillingModal'
import SharedAccess from './SharedAccess'
import Table from './Table'
import ViewKey from './ViewKey'

const Settingg = () => {
    const [name,setName]=useState("Account")
    const [modal,setModal]=useState(true)
  return (
    <Layout>
     {modal && <BillingModal setModal={setModal}/>}
        <div id="content">
        <Topbar name={"Settings"} suname={"Account"}/>
        
        <div className="container-fluid">
        <div className="dashboard-tabs project-tabs settings-tabs">
      
                        <ul className="nav nav-tabs">
                        {setting.map(({title})=>{
                            return(
                                <li className="active mr-2"><a className={name===title?"active":""} data-toggle="tab" to="#account" onClick={()=>setName(title)}>
                                <div className="icn-cont"><i className="fas fa-user-cog"></i></div>{title}</a>
                                </li>
                            )
                        })}

                            
                            
                        </ul>
                      
                      <div className="tab-content">
                       {name==="Account" && <Account/>}
                       {name==="Billing & My Plan" && <Billing/>}
                       {name==="Shared Access" && <SharedAccess/>}
                       {name==="Viewkey Brand" &&  <ViewKey/>}
                       {name==="Table" &&  <Table/>}
                        
                        
                        
                       
                       
                        
                        
                      </div>
                    </div>

        </div>

        </div>
    </Layout>
  )
}

export default Settingg