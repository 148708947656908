import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { SaveIcn } from '../../assests/images/icons'
import Newproject from './Newproject'

const SharedAccess = () => {
    const[showSidebar,setShowSidebar]=useState(false)
  return (
    <div id="shared-access" className="tab-pane in active">
    <h3 className="mt-4">Shared Access</h3>
    <div className="table-responsive">
     <table className="table table-bordered table-striped">
         <thead>
             <tr>
                 <th>Email</th>
                 <th>Password</th>
                 <th>Access</th>
                 <th>Restrictions</th>
                 <th>&nbsp;</th>
             </tr>
         </thead>
         <tbody>
             <tr>
                 <td><input type="text" className="form-control" value="demo@gmail.com"/></td>
                 <td><input type="password" className="form-control" value="12345"/></td>
                 <td>
                     <select name="" id="" className="form-control">
                         <option>Enabled</option>
                         <option>Disabled</option>
                     </select>
                 </td>
                 <td>
                     <select name="" id="" className="form-control">
                         <option>Keyword Master Account</option>
                     </select>
                 </td>
                 <td><span className="tip" data-toggle="tooltip" data-placement="left" title="There must always be at least 1 Admin User to avoid being locked out of the account entirely"><i className="fas fa-info-circle"></i></span></td>
             </tr>
             <tr>
                 <td><input type="text" className="form-control"/></td>
                 <td><input type="password" className="form-control"/></td>
                 <td>
                     <select name="" id="" className="form-control">
                         <option>Enabled</option>
                         <option>Disabled</option>
                     </select>
                 </td>
                 <td>
                     <select name="" id="" className="form-control">
                         <option>Select Restriction</option>
                         <option>Keyword Master Account</option>
                     </select>
                 </td>
                 <td><Link to="#" className="text-dark"><i className="far fa-trash-alt"></i></Link></td>
             </tr>
         </tbody>
     </table>
    </div>
    <div className="btns-cont">
     <Link to="#" className="btn btn-success add-project-btn" onClick={()=>setShowSidebar(!showSidebar)}><SaveIcn className="mr-1"/>Save Changes</Link>
     <Link to="#" className="btn btn-outline-dark"><i className="fas fa-plus-circle mr-1"></i> New User</Link>
    </div>
    {showSidebar && <Newproject setShowSidebar={setShowSidebar}/>}
 </div>
  )
}

export default SharedAccess