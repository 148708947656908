import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Layout from '../../assests/components/Layout/Layout'
import Topbar from '../../assests/components/Layout/Topbar'
import { ActionIcn, DelIcn, DownIcn, DownIcn2, InfoIcn1, InfoIcn2, InfoIcn3 ,ArchiveIcn, DownIcn3,EditIcn,ViewkeyIcn, RankUp} from '../../assests/images/icons'
import { cards, DashboardTable } from '../../assests/mockdata/Array'
import Action from './Action'
import ArchiveProject from './ArchiveProject'
import EditProject from './EditProject'
import ExportCsv from './ExportCsv'
import ExportModal from './ExportModal'
import TableData from './TableData'
import ViewKey from './ViewKey'
import Chart from 'chart.js';
import LineChart from './LineChart'
import LineChart2 from './LineChart2'
const Dashboard = () => {
    const [showSearch,setShowSearch]=useState(false)
    const [exportModal,setExportModal]=useState(false)
    const [showAction,setShowAction]=useState(false)
    const [showCharts,setShowCharts]=useState("chart")
    const  [showPdf,setShowPdf]=useState(false)
    const  [showCsv,setShowCsv]=useState(false)
    const  [showVkey,setShowVkey]=useState(false)
    const  [showEdit,setShowEdit]=useState(false)
    const  [archive,setArchive]=useState(false)
    const showChart=()=>{
        setShowCharts("chart")
       
    }
    const showTable=()=>{
        setShowCharts("table")
       
    }
   
  return (
   <>
   <Layout>
   {
    exportModal &&
    <ExportModal setExportModal={setExportModal}/>
  }

  { showCsv && <ExportCsv setShowCsv={setShowCsv}/>}
  {showVkey && <ViewKey setShowVkey={setShowVkey}/>}
  {showEdit && <EditProject setShowEdit={setShowEdit}/>}
  {archive && <ArchiveProject setArchive={setArchive}/>}
   <div id="content">
    <Topbar name={"Dashboard"} subname={"Last update (18/10/22)"}/>
    <div className="container-fluid">

{/* <!-- Page Heading --> */}


{/* <!-- Info Gadgets --> */}
<div className="info-gadgets-cont grid-7 mb-4">
{ cards.map(({theme,img,down,counts,text,footer},index)=>{
    return(
        <div className={`info-gadget ${theme}`}>
        <div className="gadget-body">
            <div className="icn-cont">
            {index===0? img:
            <>
                {img}
                <Link to="#" className="dwnld">{down}</Link>
                </>
}
            </div>
            <div className="counts"> 
               {counts}
            </div>
            <div className="count-info">
                {text} 
            </div>
        </div>
        <div className="gadget-footer">
            <Link to="#" className="gadget-btn">{footer}</Link>
        </div>
    </div>

    )
})}
    
    <div className="info-gadget gadget-action">
        <div className="gadget-body">
            <div className="icn-cont">
                <Link to="#" className="del"><DelIcn/></Link>
            </div>
            <div className="day-badge"> 
                <span className="badge badge-active">1 Day</span>     
            </div>
            <div className="day-badge"> 
                <span className="badge">7 Day</span>     
            </div> 
            <div className="day-badge"> 
                <span className="badge">30 Day</span>     
            </div>
            <div className="btn-cont">
                <Link to="#" className="gadget-btn">Start</Link>
            </div>
        </div>
        
    </div>
</div>
{/* <!-- Content Row --> */}
<div className="dashboard-tabs">
    <ul className="nav nav-tabs">
        <li className="active mr-4"><a className={showCharts==="chart"?"active":""} data-toggle="tab" to="#home" onClick={showChart}><i className="fas fa-chart-line" ></i></a></li>
        <li className={showCharts==="table"?'active':""}><a data-toggle="tab" class={`projects-table-btn ${showCharts==="table"?"active":""}`} to="#projects-table"  onClick={showTable}><i class="fas fa-list-ul"></i></a></li>
        <li className="tab-search ml-4" onClick={()=>setShowSearch(!showSearch)}>
            <Link to="#" className="text-dark tab-search-toggle mr-2"><i className="fas fa-search fa-sm"></i></Link>
            <div className={showSearch?"tab-search-box show-search":"tab-search-box"} >
                <div className="search-box">
                    <input type="text" placeholder="search"/>
                    <button className="btn search-btn p-0"><i className="fas fa-search fa-sm"></i></button>
                </div>
            </div>
        </li>
        <li className="tab-right-controls ml-auto">
            <div className="tabs-controls">
                <div className="sort-check">
                    <div className="cl-toggle-switch">
                        <label className="cl-switch">
                            <input type="checkbox"/>
                            <span></span>
                        </label>
                       
                      </div>
                      <span className="sort-txt">Sort by Changes When Sorting</span>
                    {/* <!--<label><input type="checkbox" name="" id=""> Sort by Changes When Sorting</label>--> */}
                </div>
                <div className="dwnld-data" data-toggle="modal" data-target="#exportModal" onClick={()=>setExportModal(!exportModal)}>
                <DownIcn2/></div>
            </div>
        </li>
    </ul>
  
  <div className="tab-content">
  {showCharts==="chart" &&
  <div id="home" className="tab-pane in active">
        <div className="card shadow-sm mb-4">
            <div className="card-body">
                <div className="action-control-cont ">
                    <div className="dropdown no-arrow">
                        <Link to="#" id="homeActionDropdown1" className="dropdown-toggle" role="button" data-toggle="dropdown" onClick={()=>setShowAction(!showAction)} >
                        
                        <ActionIcn/></Link>
                      {showAction &&  
                        <div className="dropdown-menu dropdown-menu-right shadow show" aria-labelledby="homeActionDropdown1">
                            <Link className="dropdown-item" to="#" onClick={()=>setShowPdf(!showPdf)}>
                               <DownIcn3  className="mr-1"/>
                                PDF
                            </Link>
                            <Link className="dropdown-item" to="#" data-toggle="modal" onClick={()=>setShowCsv(!showCsv)} data-target="#csvModal">
                            <DownIcn3  className="mr-1"/>
                                CSV
                            </Link>
                            <Link className="dropdown-item" to="#" data-toggle="modal" onClick={()=>setShowVkey(!showVkey)} data-target="#viewkeyModal">
                              <ViewkeyIcn  className="mr-1"/>
                                Get Viewkey
                            </Link>
                            <Link className="dropdown-item edit-project-btn" onClick={()=>setShowEdit(!showEdit)} to="#">
                               <EditIcn  className="mr-1"/>
                                Edit Project
                            </Link>
                            <Link className="dropdown-item" to="#" data-toggle="modal" onClick={()=>setArchive(!archive)} data-target="#archiveModal">
                               <ArchiveIcn  className="mr-1"/>
                                Archive Project
                            </Link>
                            
                          
                        </div>}
                       
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <div className="title-header">
                            <Link to="#">towingbaltimore.us</Link>
                            <span className="keywords-count">85 Total Keywords </span>
                        </div>
                        <div className="info-gadgets-cont grid-3">
                            <div className="info-gadget theme-orng">
                                <div className="gadget-body">
                                    <div className="icn-cont">
                                       <InfoIcn1/>
                                    </div>
                                    <div className="counts"> 
                                        649
                                    </div>
                                    <div className="count-info">
                                        Keywords Up 
                                    </div>
                                </div>
                                <div className="gadget-footer">
                                    <Link to="#" className="gadget-btn">Since Start</Link>
                                </div>
                            </div>
                            <div className="info-gadget theme-purple">
                                <div className="gadget-body">
                                    <div className="icn-cont">
                                       <InfoIcn2/>
                                        <Link to="#" className="dwnld"><img src="img/icons/dwnld-icn.svg" alt="" /></Link>
                                    </div>
                                    <div className="counts"> 
                                        989 / 2433 
                                    </div>
                                    <div className="count-info">
                                        In Top 30  
                                    </div>
                                </div>
                                <div className="gadget-footer">
                                    <Link to="#" className="gadget-btn">-238 since start</Link>
                                </div>
                            </div>
                            <div className="info-gadget theme-green">
                                <div className="gadget-body">
                                    <div className="icn-cont">
                                       <InfoIcn3/>
                                        <Link to="#" className="dwnld"><DownIcn/></Link>
                                    </div>
                                    <div className="counts"> 
                                        354/2433  
                                    </div>
                                    <div className="count-info">
                                        In Top 3 
                                    </div>
                                </div>
                                <div className="gadget-footer">
                                    <Link to="#" className="gadget-btn">-165 since start </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="chart-area">
                           
                            <LineChart2 className="myAreaChart2"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="card shadow-sm">
            <div className="card-body">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <div className="title-header">
                            <Link to="#">towingbaltimore.us</Link>
                            <span className="keywords-count">85 Total Keywords </span>
                        </div>
                        <div className="info-gadgets-cont grid-3">
                            <div className="info-gadget theme-orng">
                                <div className="gadget-body">
                                    <div className="icn-cont">
                                       <InfoIcn1/>
                                    </div>
                                    <div className="counts"> 
                                        649
                                    </div>
                                    <div className="count-info">
                                        Keywords Up 
                                    </div>
                                </div>
                                <div className="gadget-footer">
                                    <Link to="#" className="gadget-btn">Since Start</Link>
                                </div>
                            </div>
                            <div className="info-gadget theme-purple">
                                <div className="gadget-body">
                                    <div className="icn-cont">
                                       <InfoIcn2/>
                                        <Link to="#" className="dwnld"><DownIcn/></Link>
                                    </div>
                                    <div className="counts"> 
                                        989 / 2433 
                                    </div>
                                    <div className="count-info">
                                        In Top 30  
                                    </div>
                                </div>
                                <div className="gadget-footer">
                                    <Link to="#" className="gadget-btn">-238 since start</Link>
                                </div>
                            </div>
                            <div className="info-gadget theme-green">
                                <div className="gadget-body">
                                    <div className="icn-cont">
                                       <InfoIcn3/>
                                        <Link to="#" className="dwnld"><DownIcn/></Link>
                                    </div>
                                    <div className="counts"> 
                                        354/2433  
                                    </div>
                                    <div className="count-info">
                                        In Top 3 
                                    </div>
                                </div>
                                <div className="gadget-footer">
                                    <Link to="#" className="gadget-btn">-165 since start </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="chart-area">
                           
                            <LineChart className="myAreaChart"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> }
    
    { showCharts ==="table" && <TableData />
    }
    
    
  </div>
 
</div>

{/* <!-- Content Row --> */}



{/* <!-- Content Row --> */}


</div>
    </div>
    
   </Layout>
    
   </>
  )
}

export default Dashboard
