import React from 'react'
import { Link } from 'react-router-dom'
import {Profile1, Profile2, Profile3} from "../../images"
const MessageCenter = ({setShowMsg,showMsg}) => {
  return (
   <>
    <div className={`dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in ${showMsg?"show":""}`}
            aria-labelledby="messagesDropdown">
            <h6 className="dropdown-header">
                Message Center
            </h6>
            <Link className="dropdown-item d-flex align-items-center" to="#">
                <div className="dropdown-list-image mr-3">
                    {/* <img className="rounded-circle" src={Profile1} */}
                    <Profile1 className="rounded-circle"/>
                        
                    <div className="status-indicator bg-success"></div>
                </div>
                <div className="font-weight-bold">
                    <div className="text-truncate">Hi there! I am wondering if you can help me with a
                        problem I've been having.</div>
                    <div className="small text-gray-500">Emily Fowler · 58m</div>
                </div>
            </Link>
            <Link className="dropdown-item d-flex align-items-center" to="#">
                <div className="dropdown-list-image mr-3">
                    {/* <img className="rounded-circle" src={Profile2}
                        alt="..."/> */}
                        <Profile2 className="rounded-circle"/>
                    <div className="status-indicator"></div>
                </div>
                <div>
                    <div className="text-truncate">I have the photos that you ordered last month, how
                        would you like them sent to you?</div>
                    <div className="small text-gray-500">Jae Chun · 1d</div>
                </div>
            </Link>
            <Link className="dropdown-item d-flex align-items-center" to="#">
                <div className="dropdown-list-image mr-3">
                    {/* <img className="rounded-circle" src={Profile3}
                        alt="..."/> */}
                        <Profile3 className="rounded-circle"/>
                    <div className="status-indicator bg-warning"></div>
                </div>
                <div>
                    <div className="text-truncate">Last month's report looks great, I am very happy with
                        the progress so far, keep up the good work!</div>
                    <div className="small text-gray-500">Morgan Alvarez · 2d</div>
                </div>
            </Link>
            <Link className="dropdown-item d-flex align-items-center" to="#">
                <div className="dropdown-list-image mr-3">
                    <img className="rounded-circle" src="https://source.unsplash.com/Mv9hjnEUHR4/60x60"
                        alt="..."/>

                    <div className="status-indicator bg-success"></div>
                </div>
                <div>
                    <div className="text-truncate">Am I a good boy? The reason I ask is because someone
                        told me that people say this to all dogs, even if they aren't good...</div>
                    <div className="small text-gray-500">Chicken the Dog · 2w</div>
                </div>
            </Link>
            <Link className="dropdown-item text-center small text-gray-500" to="#">Read More Messages</Link>
        </div>
   </>
  )
}

export default MessageCenter