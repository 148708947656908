import React from 'react'
import { Link } from 'react-router-dom'
import { AddIcn } from '../../assests/images'
import { ProjectName } from '../../assests/images/icons'

const AddProjectPopup = ({setOpen,open}) => {
  return (
    <div>
         <div className={`add-new-project side-popup ${open? "open":""}`}>
        <div className="title">
            <h3>New Project</h3>
            <div className="close-side-popup" onClick={()=>setOpen(false)}>&times;</div>
        </div>
        <div className="field-group">
            <label><ProjectName/> Project Name</label>
            <input type="text" className="form-control shadow-sm" placeholder="Enter a name for your Project"/>
        </div>
        <div className="side-btm btns-cont">
            <Link to="#" className="btn btn-success btn-block add-project-btn">
            
            <AddIcn className="mr-1"/>
            Add Project</Link>
            <Link to="#" className="btn btn-danger btn-block cancel-btn" onClick={()=>setOpen(false)}>Cancel</Link>
        </div>
    </div>
    </div>
  )
}

export default AddProjectPopup