import React from 'react'
import { Link } from 'react-router-dom'

const RankingModal = ({setShowRanking}) => {
  return (
    <div class="modal fade show " id="show-rankings" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-modal="true" style={{display:'block'}}>
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <div class="modal-title"><h5>Historical Ranking URLs</h5></div>
            </div>
            <div class="close-modal">
                <button class="close" type="button" data-dismiss="modal" aria-label="Close" onClick={()=>setShowRanking(false)}>
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="table-responsive">
                    <table class="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Rank</th>
                                <th>URL</th>
                                <th>Historical SERP</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>22-11-03</td>
                                <td>1</td>
                                <td>vad@logicalcommerce.com </td>
                                <td><Link to="#">View Serp</Link></td>
                            </tr>
                            <tr>
                                <td>22-11-03</td>
                                <td>1</td>
                                <td>vad@logicalcommerce.com </td>
                                <td><Link to="#">View Serp</Link></td>
                            </tr>
                            <tr>
                                <td>22-11-03</td>
                                <td>1</td>
                                <td>vad@logicalcommerce.com </td>
                                <td><Link to="#">View Serp</Link></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            
        </div>
    </div>
</div>
  )
}

export default RankingModal