import React from 'react'
import { Link } from 'react-router-dom'
import "../../Style/sb-admin-2.css"
const LogOut = ({setLogout}) => {
  return (
   <>
     <div className="modal fade show" id="logoutModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     style={{display:'block' }} aria-modal="true"
       >
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
                    <button className="close" type="button"  aria-label="Close"  onClick={()=>setLogout(false)}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body">Select "Logout" below if you are ready to end your current session.</div>
                <div className="modal-footer">
                    <button className="btn btn-secondary" type="button" data-dismiss="modal" onClick={()=>setLogout(false)}>Cancel</button>
                    <Link className="btn btn-primary" to="login.html">Logout</Link>
                </div>
            </div>
        </div>
    </div>  
   </>
  )
}

export default LogOut